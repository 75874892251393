import { API } from 'api/API';
import NeedLoginLayout from 'layout/Admin/NeedLoginLayout';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const NoticeBlock = ({ notice, onTitleChange, onContentChange, disabled }) => {
  return (
    <div className="flex flex-col">
      <input
        disabled={disabled}
        type="text"
        className="border-2 bg-transparent border-gray-300 rounded-xl px-5 py-2 mt-2 w-96"
        placeholder="제목"
        value={notice.title}
        onChange={e => {
          onTitleChange(e.target.value);
        }}
      />
      <textarea
        disabled={disabled}
        className="border-2 bg-transparent border-gray-300 rounded-xl px-5 py-2 mt-2 resize-none h-96 w-96"
        placeholder="내용"
        value={notice.content}
        onChange={e => {
          onContentChange(e.target.value);
        }}
      />
    </div>
  );
};

const EditMainNotice = () => {
  const { accessToken } = useSelector(state => state.adminAuth);
  const [newNotice, setNewNotice] = useState({
    title: '',
    content: '',
  });
  const [notices, setNotices] = useState([]);

  const { data: noticesData, refetch: refetchNotices } = API.useGetNotions();
  const { mutateAsync: createNotice } = API.useCreateNotions();
  const { mutateAsync: updateNotice } = API.useUpdateNotions();
  const { mutateAsync: deleteNotice } = API.useDeleteNotions();

  const onCreate = () => {
    if (newNotice.title === '' || newNotice.content === '') {
      alert('제목과 내용을 입력해주세요.');
      return;
    }
    createNotice({
      accessToken,
      title: newNotice.title,
      content: newNotice.content,
    }).then(res => {
      if (res.success) {
        setNewNotice({
          title: '',
          content: '',
        });
        alert('공지가 추가되었습니다.');
        refetchNotices();
      }
    });
  };
  const onSubmit = index => {
    if (notices[index].title === '' || notices[index].content === '') {
      alert('제목과 내용을 입력해주세요.');
      return;
    }
    updateNotice({
      accessToken,
      noticeId: notices[index]._id,
      title: notices[index].title,
      content: notices[index].content,
    }).then(res => {
      if (res.success) {
        alert('공지가 수정되었습니다.');
        setNotices(prev => {
          const newNotices = [...prev];
          newNotices[index].isEdit = false;
          return newNotices;
        });
        refetchNotices();
      } else {
        alert('공지 수정에 실패했습니다.');
      }
    });
  };

  const onDelete = index => {
    if (window.confirm('정말로 삭제하시겠습니까?')) {
      deleteNotice({ accessToken, noticeId: notices[index]._id }).then(res => {
        if (res.success) {
          alert('공지가 삭제되었습니다.');
          refetchNotices();
        } else {
          alert('공지 삭제에 실패했습니다.');
        }
      });
    }
  };

  useEffect(() => {
    if (noticesData) {
      if (noticesData.success) {
        setNotices(
          noticesData.data.map(item => ({
            ...item,
            isEdit: false,
          })),
        );
      }
    }
  }, [noticesData]);

  return (
    <NeedLoginLayout>
      <div className="p-10 flex flex-col">
        <div className="h-20 w-full flex flex-row">
          <p className="text-3xl font-semibold text-gray-500">
            * 메인 공지 수정
          </p>
        </div>
        <div className="flex flex-col">
          <div className="flex mb-2">
            <p className="text-2xl text-gray-500 font-semibold">
              - 새로운 공지
            </p>
            <button
              className="text-xl rounded-xl ml-5 px-5 text-white bg-skyblue2"
              onClick={onCreate}
            >
              추가
            </button>
          </div>
          <NoticeBlock
            notice={newNotice}
            onTitleChange={value => {
              setNewNotice(prev => ({
                ...prev,
                title: value,
              }));
            }}
            onContentChange={value => {
              setNewNotice(prev => ({
                ...prev,
                content: value,
              }));
            }}
          />
        </div>
        <div className="flex flex-col mt-10">
          <div className="flex mb-2">
            <p className="text-2xl text-gray-500 font-semibold">- 공지 목록</p>
          </div>
          <div className="flex w-full overflow-x-scroll">
            {notices.map((item, index) => (
              <div
                key={item._id}
                className="flex flex-col mx-5 first:ml-0 last:mr-0"
              >
                <NoticeBlock
                  notice={item}
                  onTitleChange={value => {
                    setNotices(prev => {
                      const newNotices = [...prev];
                      newNotices[index].title = value;
                      return newNotices;
                    });
                  }}
                  onContentChange={value => {
                    setNotices(prev => {
                      const newNotices = [...prev];
                      newNotices[index].content = value;
                      return newNotices;
                    });
                  }}
                  disabled={!item.isEdit}
                />
                <div className="w-full flex justify-end mt-2">
                  <button
                    className="text-xl rounded-xl ml-5 px-5 py-1 text-white bg-red1"
                    onClick={() => onDelete(index)}
                  >
                    삭제
                  </button>
                  <button
                    hidden={item.isEdit}
                    className="text-xl rounded-xl ml-5 px-5 py-1 text-white bg-skyblue2"
                    onClick={() =>
                      setNotices(prev => {
                        const newNotices = [...prev];
                        newNotices[index].isEdit = true;
                        return newNotices;
                      })
                    }
                  >
                    수정
                  </button>
                  <button
                    hidden={!item.isEdit}
                    className="text-xl rounded-xl ml-5 px-5 py-1 text-white bg-skyblue2"
                    onClick={() => onSubmit(index)}
                  >
                    저장
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </NeedLoginLayout>
  );
};

export default EditMainNotice;
