import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Main from 'page/Admin/Main';
import UserList from 'page/Admin/UserList';
import Login from 'page/Admin/Login';
import DetailedUser from 'page/Admin/DetailedUser';
import GymList from 'page/Admin/GymList';
import AddGym from 'page/Admin/AddGym';
import EditGymRoute from './EditGymRoute';
import EditMainBanner from 'page/Admin/EditMainBanner';
import EditMainNotice from 'page/Admin/EditMainNotice';
import AddOfficialHoliday from 'page/Admin/AddOfficialHoliday';
import Statistics from 'page/Admin/Statistics';
import CancelReservation from 'page/Admin/CancelReservation';
import PageNotFound from 'page/Error/PageNotFound';

const AdminRoute = () => {
  return (
    <Routes>
      <Route index element={<Main />} />
      <Route path="user-list" element={<UserList />} />
      <Route path="detailed-users/:userid" element={<DetailedUser />} />
      <Route path="gym-list" element={<GymList />} />
      <Route path="edit-gym/:gymid/*" element={<EditGymRoute />} />
      <Route path="add-gym" element={<AddGym />} />
      <Route path="login" element={<Login />} />
      <Route path="edit-main-banner" element={<EditMainBanner />} />
      <Route path="edit-main-notice" element={<EditMainNotice />} />
      <Route path="add-official-holiday" element={<AddOfficialHoliday />} />
      <Route path="statistics" element={<Statistics />} />
      <Route path="cancel-reservation" element={<CancelReservation />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default AdminRoute;
