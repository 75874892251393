import { API } from 'api/API';
import NeedLoginLayout from 'layout/Admin/NeedLoginLayout';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import SetPointModal from './SetPointModal';

const reservationStatusName = {
  wait: '대기',
  cancel: '취소',
  end: '완료',
};
const defaultPoint = {
  open: false,
  origin: 0,
  addPoint: 0,
  subPoint: 0,
  reason: '',
};

function DetailedUser() {
  const { userid } = useParams();
  const { accessToken } = useSelector(state => state.adminAuth);

  const { data: userData } = API.useGetUser({ userId: userid, accessToken });
  const { mutateAsync: setUserPoint } = API.useSetUserPoint();

  const [point, setPoint] = useState({
    ...defaultPoint,
  });

  const onSetPoint = () => {
    if (point.reason === '') {
      alert('사유를 입력해주세요.');
    } else {
      const updatedPoint = parseInt(point.addPoint) - parseInt(point.subPoint);
      setUserPoint({
        userId: userid,
        point: updatedPoint,
        reason: point.reason,
        accessToken,
      }).then(res => {
        if (res.success) {
          setPoint({
            ...defaultPoint,
            open: false,
          });
          if (updatedPoint > 0) {
            alert('포인트가 지급되었습니다.');
          } else {
            alert('포인트가 회수되었습니다.');
          }
        } else if (updatedPoint > 0) {
          alert('포인트 지급에 실패하였습니다.');
        } else {
          alert('포인트 회수에 실패하였습니다.');
        }
      });
    }
  };

  return (
    <NeedLoginLayout>
      <div className="w-full flex flex-col py-4 px-20">
        <div className="w-full grid grid-cols-2 gap-20 place-items-start pt-10">
          <div className="flex w-full place-content-center place-items-center">
            <p className="text-2xl mr-3 whitespace-nowrap">이름</p>
            <p className="px-3 bg-white2 text-xl w-full">
              {userData?.data?.name}
            </p>
          </div>
          <div className="flex w-full place-content-center place-items-center">
            <p className="text-2xl whitespace-nowrap">보유 캐쉬</p>
            <p className="px-3 ml-3 bg-white2 text-xl w-full">
              {userData?.data?.point}
            </p>
            <button
              onClick={() => {
                setPoint({
                  ...defaultPoint,
                  origin: userData?.data?.point,
                  open: true,
                });
              }}
              className="bg-green-300 text-xl px-2 ml-2 whitespace-nowrap"
            >
              포인트 지급 및 회수
            </button>
          </div>
        </div>
        <p className="border-b-2 flex w-full my-5 pb-3 text-2xl">예약내역</p>
        <div className="flex w-full max-h-60 overflow-y-scroll">
          <table className="table-fixed w-full">
            <thead>
              <tr className="border-2 border-gray-300">
                <th className="text-xl text-center w-60">체육관 이름</th>
                <th className="text-xl text-center w-48">비용</th>
                <th className="text-xl text-center w-60">상태</th>
              </tr>
            </thead>
            <tbody>
              <tr className="h-2" />
              {userData?.data?.reservations?.map(reservation => (
                <tr
                  key={reservation._id}
                  className="border-2 border-gray-300 h-16"
                >
                  <td className="text-xl text-center">{reservation.gymName}</td>
                  <td className="text-xl text-center">{reservation.point}</td>
                  <td className="text-xl text-center">
                    {reservationStatusName[reservation.status]}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <p className="border-b-2 flex w-full my-5 pb-3 text-2xl">
          캐쉬 사용내역
        </p>
        <div className="flex w-full max-h-60 overflow-y-scroll">
          <table className="table-fixed w-full">
            <thead>
              <tr className="border-2 border-gray-300">
                <th className="text-xl text-center w-60">사유</th>
                <th className="text-xl text-center w-48">포인트</th>
                <th className="text-xl text-center w-60">총합 포인트</th>
              </tr>
            </thead>
            <tbody>
              <tr className="h-2" />
              {userData?.data?.pointHistories
                ?.filter(p => p.point !== null)
                .map(pointHistory => (
                  <tr
                    key={pointHistory._id}
                    className="border-2 border-gray-300 h-16"
                  >
                    <td className="text-xl text-center">
                      {pointHistory.reason}
                    </td>
                    <td className="text-xl text-center">
                      {pointHistory.point}
                    </td>
                    <td className="text-xl text-center">
                      {pointHistory.chargePoint}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <p className="border-b-2 flex w-full my-5 pb-3 text-2xl">
          예약 취소 신청 내역
        </p>
        <div className="flex w-full max-h-60 overflow-y-scroll mb-5">
          <table className="table-fixed w-full">
            <thead>
              <tr className="border-2 border-gray-300">
                <th className="text-xl text-center w-60">체육관 이름</th>
                <th className="text-xl text-center w-48">사유</th>
              </tr>
            </thead>
            <tbody>
              <tr className="h-2" />
              {userData?.data?.cancelReservations?.map(cancel => (
                <tr key={cancel._id} className="border-2 border-gray-300 h-16">
                  <td className="text-xl text-center">{cancel.gymName}</td>
                  <td className="text-xl text-center">{cancel.reason}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <SetPointModal
        point={point}
        setPoint={setPoint}
        defaultPoint={defaultPoint}
        onSubmit={onSetPoint}
      />
    </NeedLoginLayout>
  );
}

export default DetailedUser;
