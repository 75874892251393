import {
  BellIcon,
  PencilSquareIcon,
  CalendarDaysIcon,
  PhotoIcon,
  AcademicCapIcon,
  ArrowLeftOnRectangleIcon,
} from '@heroicons/react/24/outline';

const basicGymRouteList = gymId => [
  {
    id: 'back',
    Icon: ({ className }) => <ArrowLeftOnRectangleIcon className={className} />,
    title: '돌아가기',
    link: -1,
  },
  {
    id: 'edit-gym-info',
    Icon: ({ className }) => <PencilSquareIcon className={className} />,
    title: '정보 수정',
    link: `/admin/edit-gym/${gymId}/info`,
  },
  {
    id: 'edit-gym-holiday',
    Icon: ({ className }) => <CalendarDaysIcon className={className} />,
    title: '휴무일 수정',
    link: `/admin/edit-gym/${gymId}/holiday`,
  },
  {
    id: 'edit-gym-notice',
    Icon: ({ className }) => <BellIcon className={className} />,
    title: '공지 수정',
    link: `/admin/edit-gym/${gymId}/notice`,
  },
  {
    id: 'edit-gym-banner',
    Icon: ({ className }) => <PhotoIcon className={className} />,
    title: '배너 수정',
    link: `/admin/edit-gym/${gymId}/banner`,
  },
  {
    id: 'edit-gym-reservation',
    Icon: ({ className }) => <CalendarDaysIcon className={className} />,
    title: '예약 목록',
    link: `/admin/edit-gym/${gymId}/reservation`,
  },
];

const ClassGymRouteList = gymId => [
  {
    id: 'edit-gym-course',
    Icon: ({ className }) => <AcademicCapIcon className={className} />,
    title: '강좌 수정',
    link: `/admin/edit-gym/${gymId}/course`,
  },
];

const EditGymRouteList = (gymId, type) => {
  if (type === 'class') {
    return [...basicGymRouteList(gymId), ...ClassGymRouteList(gymId)];
  } else {
    return basicGymRouteList(gymId);
  }
};

export default EditGymRouteList;
