import React, { useEffect } from 'react';
import TagCard from 'component/Admin/TagCard';
import { Link, useParams, useNavigate } from 'react-router-dom';
import EditGymRouteList from 'data/Admin/EditGymRouteList';
import EditGymLayout from 'layout/Admin/EditGymLayout';
import { API } from 'api/API';

const EditGymMain = () => {
  const { gymid } = useParams();
  const navigate = useNavigate();

  const { data: gymData } = API.useGetGymDetails({ gymId: gymid });

  useEffect(() => {
    if (gymData) {
      if (!gymData?.success) {
        alert('잘못된 접근입니다.');
        navigate(-1);
      }
    }
  }, [gymData, navigate]);

  if (!gymData?.success) return null;
  return (
    <EditGymLayout gym={gymData.data}>
      <div className="p-10 grid grid-flow-row grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-10">
        {EditGymRouteList(gymid, gymData?.data?.type)?.map(item => (
          <TagCard key={item.id}>
            <Link to={item.link} className="w-full h-full flex flex-row px-7">
              <item.Icon className="w-10 h-10 my-auto stroke-skyblue2" />
              <p className="ml-auto my-auto text-skyblue2 text-2xl">
                {item.title}
              </p>
            </Link>
          </TagCard>
        ))}
      </div>
    </EditGymLayout>
  );
};

export default EditGymMain;
