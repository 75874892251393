import axios from 'axios';
import GymAPI from './gymAPI';
import MainAPI from './mainAPI';
import UserAPI from './userAPI';
import CityAPI from './cityAPI';
import CourseAPI from './courseAPI';
import AdminAPI from './adminAPI';
import MediaAPI from './mediaAPI';
import ReservationAPI from './reservationAPI';
import StatisticAPI from './statisticAPI';
import PurchaseAPI from './purchaseAPI';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export const ajax = async options => {
  if (options?.headers?.Authorization === 'Bearer ')
    return { success: false, data: 'Token is required' };
  let result;
  await axios(options)
    .then(response => {
      result = { success: true, data: response?.data };
    })
    .catch(error => {
      result = { success: false, data: error };
    });
  return result;
};

export const { IMP } = window;

export const API = {
  ...GymAPI,
  ...MainAPI,
  ...UserAPI,
  ...CityAPI,
  ...CourseAPI,
  ...AdminAPI,
  ...MediaAPI,
  ...ReservationAPI,
  ...StatisticAPI,
  ...PurchaseAPI,
};
