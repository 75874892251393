import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearUser } from '../store/userAuthSlice';

const key = process.env.REACT_APP_KAKAO_JSKEY;

const useKakaoLogin = (redirect = '/') => {
  const Kakao = window.Kakao;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (Kakao && !Kakao.isInitialized()) {
      Kakao.init(key);
    }
  }, [Kakao]);
  const login = () => {
    Kakao.Auth.authorize({
      redirectUri: process.env.REACT_APP_API_URL + '/auth/kakao-sign-in',
      state: redirect,
    });
  };
  const logout = () => {
    dispatch(clearUser());
    navigate('/', { replace: true });
  };
  return { login, logout };
};

export default useKakaoLogin;
