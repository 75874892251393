import { useQuery, useMutation } from 'react-query';
import { ajax } from './API';

const useGetUsers = ({ accessToken }) => {
  const options = {
    method: 'GET',
    url: '/admin/users',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return useQuery('users', () => ajax(options));
};

const useGetUser = ({ accessToken, userId }) => {
  const options = {
    method: 'GET',
    url: `/admin/users/${userId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return useQuery(['user', userId], () => ajax(options));
};

const useSetUserPoint = () => {
  return useMutation(({ accessToken, userId, point, reason }) =>
    ajax({
      method: 'PUT',
      url: `/admin/users/${userId}/points`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        point,
        reason,
      },
    }),
  );
};

const useAdminLogin = () => {
  return useMutation(({ adminId, adminPassword }) =>
    ajax({
      method: 'POST',
      url: '/admin/login',
      data: {
        adminId,
        adminPassword,
      },
    }),
  );
};

const useSetOfficialHoliday = () => {
  return useMutation(({ accessToken, holidays }) =>
    ajax({
      method: 'PATCH',
      url: '/admin/gyms/official-holiday',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: [...holidays],
    }),
  );
};

const AdminAPI = {
  useGetUsers,
  useGetUser,
  useSetUserPoint,
  useAdminLogin,
  useSetOfficialHoliday,
};
export default AdminAPI;
