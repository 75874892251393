import { API } from 'api/API';
import NeedLoginLayout from 'layout/Admin/NeedLoginLayout';
import { useSelector } from 'react-redux';

const Statistics = () => {
  const { accessToken } = useSelector(state => state.adminAuth);

  const { data: pointSumData } = API.useGetPointSum({
    accessToken: accessToken,
  });

  const createPointSum =
    pointSumData?.data?.amountpointsSum + pointSumData?.data?.adminGivePoint;

  const allPointSum =
    pointSumData?.data?.pointsSum +
    pointSumData?.data?.gymPointsSum +
    pointSumData?.data?.adminPoint;

  const errorPoint = allPointSum - createPointSum;

  return (
    <NeedLoginLayout>
      <div className="w-full flex flex-col p-4">
        <div className="h-20 w-full flex flex-row">
          <p className="text-3xl font-semibold text-gray-500">통계페이지</p>
        </div>
        <div className="flex flex-col">
          <div className="flex mb-2">
            <p className="text-2xl text-gray-500 font-semibold">- 캐쉬 분포</p>
          </div>
          <div className="grid grid-cols-5 px-5 grid-flow-col">
            <div className="col-span-3 grid grid-cols-11 grid-flow-col">
              <div className="col-span-3 flex flex-col">
                <p className="text-xl text-gray-500 font-semibold">유저 캐쉬</p>
                <p className="text-2xl text-gray-500 border-2 my-2 text-center py-2 rounded-xl font-semibold">
                  {pointSumData?.data?.pointsSum} 캐쉬
                </p>
              </div>
              <p className="text-2xl pt-5 text-gray-500 col-span-1 flex place-content-center place-items-center font-semibold">
                +
              </p>
              <div className="col-span-3 flex flex-col">
                <p className="text-xl text-gray-500 font-semibold">
                  체육관 캐쉬
                </p>
                <p className="text-2xl text-gray-500 border-2 my-2 text-center py-2 rounded-xl font-semibold">
                  {pointSumData?.data?.gymPointsSum} 캐쉬
                </p>
              </div>
              <p className="text-2xl pt-5 text-gray-500 col-span-1 flex place-content-center place-items-center font-semibold">
                +
              </p>
              <div className="col-span-3 flex flex-col">
                <p className="text-xl text-gray-500 font-semibold">
                  어드민 캐쉬
                </p>
                <p className="text-2xl text-gray-500 border-2 my-2 text-center py-2 rounded-xl font-semibold">
                  {pointSumData?.data?.adminPoint} 캐쉬
                </p>
              </div>
            </div>
            <div className="col-span-2 grid grid-cols-5 grid-flow-col">
              <p className="text-2xl pt-5 col-span-1 text-gray-500 flex place-content-center place-items-center font-semibold">
                =
              </p>
              <div className="flex flex-col col-span-4">
                <p className="text-xl text-gray-500 font-semibold">총합</p>
                <p className="text-2xl text-gray-500 border-2 my-2 text-center py-2 rounded-xl font-semibold">
                  {allPointSum} 캐쉬
                </p>
              </div>
            </div>
          </div>
          <div className="flex mb-2 mt-5">
            <p className="text-2xl text-gray-500 font-semibold">
              - 생성된 캐쉬
            </p>
          </div>
          <div className="grid grid-cols-5 px-5">
            <div className="col-span-3 grid grid-cols-5">
              <div className="col-span-2 flex flex-col">
                <p className="text-xl text-gray-500 font-semibold">
                  충전된 캐쉬
                </p>
                <p className="text-2xl text-gray-500 border-2 my-2 text-center py-2 rounded-xl font-semibold">
                  {pointSumData?.data?.amountpointsSum} 캐쉬
                </p>
              </div>
              <p className="text-2xl pt-5 text-gray-500 col-span-1 flex place-content-center place-items-center font-semibold">
                +
              </p>
              <div className="col-span-2 flex flex-col">
                <p className="text-xl text-gray-500 font-semibold">
                  지급한 캐쉬
                </p>
                <p className="text-2xl text-gray-500 border-2 my-2 text-center py-2 rounded-xl font-semibold">
                  {pointSumData?.data?.adminGivePoint} 캐쉬
                </p>
              </div>
            </div>
            <div className="col-span-2 grid grid-cols-5 grid-flow-col">
              <p className="text-2xl pt-5 col-span-1 text-gray-500 flex place-content-center place-items-center font-semibold">
                =
              </p>
              <div className="flex flex-col col-span-4">
                <p className="text-xl text-gray-500 font-semibold">총합</p>
                <p className="text-2xl text-gray-500 border-2 my-2 text-center py-2 rounded-xl font-semibold">
                  {createPointSum} 캐쉬
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full pt-10 place-content-center place-items-center">
            <div className="flex flex-col col-span-4">
              <p
                className={`text-xl font-semibold ${
                  errorPoint !== 0 ? 'text-red1' : 'text-skyblue2'
                }`}
              >
                오차
              </p>
              <p
                className={`text-2xl border-2 mb-5 px-2 text-center py-2 rounded-xl font-semibold ${
                  errorPoint !== 0
                    ? 'text-red1 border-red1'
                    : 'text-skyblue2 border-skyblue2'
                }`}
              >
                {errorPoint} 캐쉬
              </p>
            </div>
            {errorPoint === 0 && (
              <p className="text-2xl font-bold text-skyblue2">
                오차가 없습니다
              </p>
            )}
            {errorPoint > 0 && (
              <p className="text-2xl font-bold text-red1">
                출처를 알 수 없는 캐쉬가 존재합니다
              </p>
            )}
            {errorPoint < 0 && (
              <p className="text-2xl font-bold text-red1">
                사라진 캐쉬가 존재합니다
              </p>
            )}
          </div>
        </div>
      </div>
    </NeedLoginLayout>
  );
};

export default Statistics;
