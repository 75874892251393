import { Fragment, useEffect, useState } from 'react';
import { API } from 'api/API';
import NeedLoginLayout from 'layout/Admin/NeedLoginLayout';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const UserItem = ({ user, index }) => {
  const [name, setName] = useState(user?.name || '');

  return (
    <tr key={`${user.USER_ID} ${index}`} className="text-xl h-12 border-2">
      <td className="w-[10%] text-center">{index + 1}</td>
      <td className="w-[10%] text-center">
        <input
          className="w-full text-center bg-transparent border-2 disabled:border-transparent"
          disabled={true}
          value={name}
          onChange={e => setName(e.target.value)}
        />
      </td>
      <td className="w-[40%] text-center">{user?._id}</td>
      <td className="w-[20%] text-center">{user?.point} 캐쉬</td>
      <td className="w-full">
        <div className="flex flex-row place-content-center">
          <Link to={`/admin/detailed-users/${user._id}`} className="mr-3">
            <button className="bg-green-300 rounded-xl w-16 h-full text-center text-xl font-semibold text-green-600">
              상세
            </button>
          </Link>
          <button className="bg-red-300 rounded-xl w-16 h-full text-center text-xl font-semibold text-red-600">
            삭제
          </button>
        </div>
      </td>
    </tr>
  );
};

const UserList = () => {
  const { accessToken } = useSelector(state => state.adminAuth);
  console.log(accessToken);

  const [userList, setUserList] = useState([]);

  const { data: userListData } = API.useGetUsers({ accessToken });

  useEffect(() => {
    if (userListData && userListData.success) {
      setUserList(userListData.data);
    }
  }, [userListData]);

  return (
    <NeedLoginLayout>
      <div className="w-full flex flex-col p-4">
        <div className="h-20 w-full flex flex-row">
          <p className="text-3xl font-semibold text-gray-500">Userlist</p>
        </div>

        <table className="w-full table-auto mt-3">
          <thead>
            <tr className="h-12 border-2 text-2xl">
              <th className="w-[10%]"></th>
              <th className="w-[10%]">이름</th>
              <th className="w-[40%]">아이디</th>
              <th className="w-[20%]">보유캐쉬</th>
              <th className="w-full">기능</th>
            </tr>
          </thead>
          <tbody>
            {userList?.map((user, index) => (
              <Fragment key={`${user._id} ${index}`}>
                <UserItem user={user} index={index} accessToken={accessToken} />
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </NeedLoginLayout>
  );
};

export default UserList;
