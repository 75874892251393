import { Fragment, useEffect } from 'react';

const Modal = ({ children, modalState, onClose = () => {} }) => {
  const [state, setState] = modalState;

  const outsideClick = e => {
    if (e.target === e.currentTarget) {
      setState(false);
      onClose();
    }
  };

  useEffect(() => {
    if (state) {
      window.scrollTo(0, 0);
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [state]);

  return (
    <Fragment>
      <div
        className={
          'bg-opacity-30 absolute ' +
          'z-10 fixed flex max-lg:w-full ' +
          (state
            ? 'bg-black w-full h-screen max-lg:bottom-0 lg:top-0 lg:left-0 '
            : 'bg-transparent lg:w-0 h-0 max-lg:bottom-0 lg:top-[50%] lg:left-[50%] ') +
          'transition-all duration-300 ease-in-out ' +
          'overflow-hidden place-items-center place-content-center'
        }
        onClick={outsideClick}
      />
      <div
        className={
          'bg-skyblue1 flex rounded-xl absolute z-20 overflow-hidden ' +
          'lg:top-1/2 lg:left-1/2 lg:-translate-x-1/2 lg:-translate-y-1/2 max-lg:bottom-0 ' +
          'max-lg:w-full lg:max-w-[40rem] lg:max-h-30 ' +
          (state ? 'opacity-100 ' : 'opacity-0 ') +
          'transition-all duration-300 ease-in-out'
        }
      >
        {state && children}
      </div>
    </Fragment>
  );
};

export default Modal;
