import { ajax } from './API';
import { useMutation, useQuery } from 'react-query';

const useGetGymDetails = ({ gymId }) => {
  const options = {
    method: 'GET',
    url: `/gyms/${gymId}`,
  };
  return useQuery(['gymDetails', gymId], () => ajax(options));
};

const useGetGyms = ({ cityId, regionCode }) => {
  const options = {
    method: 'GET',
    url: '/gyms',
    params: {
      cityId,
      regionCode,
    },
  };
  return useQuery(['gyms', cityId, regionCode], () => ajax(options));
};

const useCreateGymCategory = () => {
  return useMutation(({ accessToken, name }) =>
    ajax({
      method: 'POST',
      url: '/category',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        name,
      },
    }),
  );
};

const useGetGymCategories = () => {
  const options = {
    method: 'GET',
    url: '/category',
  };
  return useQuery('gymCategories', () => ajax(options));
};

const useGetGymReviews = ({ gymId }) => {
  const options = {
    method: 'GET',
    url: `/gyms/${gymId}/reviews`,
  };
  return useQuery(['gymReviews', gymId], () => ajax(options));
};

const useCreateGymReview = () => {
  return useMutation(({ accessToken, gymId, content, rating }) =>
    ajax({
      method: 'POST',
      url: `/gyms/${gymId}/reviews`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        content,
        rating,
      },
    }),
  );
};

const useCreateGym = () => {
  return useMutation(
    ({
      accessToken,
      type,
      categoryId,
      latitude,
      longitude,
      address,
      cityId,
      name,
      description,
      phoneNumber,
      master,
      manager,
      supplies,
      dayPoint,
      gymFee,
      officialHoliday,
      openTime,
      closeTime,
      openDay,
    }) =>
      ajax({
        method: 'POST',
        url: '/gyms',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          type,
          category_id: categoryId,
          latitude,
          longitude,
          address,
          city_id: cityId,
          name,
          description,
          phone_number: phoneNumber,
          master,
          manager,
          supplies,
          dayPoint,
          gymFee,
          officialHoliday,
          openTime,
          closeTime,
          openDays: openDay,
        },
      }),
  );
};

const useDeleteGym = () => {
  return useMutation(({ accessToken, gymId }) =>
    ajax({
      method: 'DELETE',
      url: `/gyms/${gymId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }),
  );
};

const useChangeGymOrder = () => {
  return useMutation(({ accessToken, gymId, direction }) =>
    ajax({
      method: 'PATCH',
      url: `/gyms/${gymId}/${direction}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }),
  );
};

const useUpdateGymInfo = () => {
  return useMutation(
    ({
      gymId,
      accessToken,
      type,
      categoryId,
      latitude,
      longitude,
      address,
      cityId,
      name,
      description,
      phoneNumber,
      master,
      manager,
      supplies,
      dayPoint,
      gymFee,
      officialHoliday,
      openTime,
      closeTime,
      openDay,
    }) =>
      ajax({
        method: 'PATCH',
        url: `/gyms/${gymId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          type,
          category_id: categoryId,
          latitude,
          longitude,
          address,
          city_id: cityId,
          name,
          description,
          phone_number: phoneNumber,
          master,
          manager,
          supplies,
          dayPoint,
          gymFee,
          officialHoliday,
          openTime,
          closeTime,
          openDay,
        },
      }),
  );
};

const useGetGymBanners = ({ gymId, accessToken }) => {
  const option = {
    method: 'GET',
    url: `/gyms/${gymId}/banners`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return useQuery(['gymBanners', gymId], () => ajax(option));
};

const useCreateGymBanner = () => {
  return useMutation(({ gymId, accessToken, image }) =>
    ajax({
      method: 'POST',
      url: `/gyms/${gymId}/banners`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        image,
      },
    }),
  );
};

const useChangeGymBannerOrder = () => {
  return useMutation(({ accessToken, bannerId, direction }) =>
    ajax({
      method: 'PATCH',
      url: `/gyms/banners/${bannerId}/${direction}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }),
  );
};

const useDeleteGymBanner = () => {
  return useMutation(({ accessToken, bannerId }) =>
    ajax({
      method: 'DELETE',
      url: `/gyms/banners/${bannerId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }),
  );
};

const useGetGymNotices = ({ gymId, accessToken }) => {
  const option = {
    method: 'GET',
    url: `/gyms/${gymId}/notices`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return useQuery(['gymNotices', gymId], () => ajax(option));
};

const useCreateGymNotice = () => {
  return useMutation(({ gymId, accessToken, showNotice, title, content }) =>
    ajax({
      method: 'POST',
      url: `/gyms/${gymId}/notices`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        showNotice,
        title,
        content,
      },
    }),
  );
};

const useDeleteGymNotice = () => {
  return useMutation(({ accessToken, noticeId }) =>
    ajax({
      method: 'DELETE',
      url: `/gyms/notices/${noticeId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }),
  );
};

const useGetGymHoliday = ({ gymId, accessToken }) => {
  const option = {
    method: 'GET',
    url: `/gyms/${gymId}/holidays`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return useQuery(['gymHoliday', gymId], () => ajax(option));
};

const useCreateGymHoliday = () => {
  return useMutation(({ gymId, accessToken, holidays }) =>
    ajax({
      method: 'POST',
      url: `/gyms/${gymId}/holidays`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: [...holidays],
    }),
  );
};

const useDeleteGymHoliday = () => {
  return useMutation(({ accessToken, holidayIds }) =>
    ajax({
      method: 'DELETE',
      url: '/gyms/holidays',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: [...holidayIds],
    }),
  );
};

const GymAPI = {
  useGetGymDetails,
  useGetGyms,
  useCreateGymCategory,
  useGetGymCategories,
  useGetGymReviews,
  useCreateGymReview,
  useCreateGym,
  useDeleteGym,
  useChangeGymOrder,
  useUpdateGymInfo,
  useGetGymBanners,
  useCreateGymBanner,
  useChangeGymBannerOrder,
  useDeleteGymBanner,
  useGetGymNotices,
  useCreateGymNotice,
  useDeleteGymNotice,
  useGetGymHoliday,
  useCreateGymHoliday,
  useDeleteGymHoliday,
};
export default GymAPI;
