import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { API } from 'api/API';
// import { useParams, useNavigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import CitySelector from 'component/CitySelector/CitySelector';

const typeName = {
  class: '수업형',
  free: '자유형',
};

const List = () => {
  // const { region, city } = useParams();
  const [region, setRegion] = useState({
    label: '서울',
    city: '439a2b45-f41d-4aa1-9506-529cafba588f',
  });
  //FIXME: 사용자 정보에 따라 기본 로드 지역 변경할 수 있도록 수정
  const navigate = useNavigate();

  const { data: gymData, refetch } = API.useGetGyms({
    cityId: region.city,
    regionCode: region.label,
  });
  const [gymList, setGymList] = useState([]);

  useEffect(() => {
    if (gymData) {
      if (gymData?.data?.gyms.length <= 0) {
        alert('해당 지역에는 체육관이 없습니다.');
        // navigate(-1);
      } else {
        setGymList(gymData.data.gyms);
      }
    }
  }, [gymData, navigate]);

  useEffect(() => {
    refetch();
  }, [region]);

  const onCitySelect = v => {
    setRegion({ label: v.label, city: v.value });
  };

  return (
    <div className="mx-auto w-full max-w-[40rem] flex flex-col px-5 lg:px-0">
      <CitySelector
        startRegion={region.label}
        startCity={region.city}
        onSelect={onCitySelect}
      />
      <div className="mt-5 w-full max-w-[40rem] grid grid-cols-2 gap-8">
        {gymList.map(item => (
          <Link to={`/info/${item._id}`} key={item._id}>
            <div className="w-full relative">
              <div className="w-full pt-[70%] bg-zinc-100 rounded-lg border-2 border-stone-300 mb-2" />
              <div className="text-black text-base font-bold mb-1">
                {item.name}
              </div>
              <div className="text-zinc-500 text-xs font-normal mb-1">
                {item.address} · {typeName[item.type]} {item.category.name}
              </div>
              <div className="text-black text-sm font-semibold">
                {item.dayPoint + item.gymFee}캐쉬/1회
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default List;
