import React, { useState } from 'react';
import Header from 'component/Admin/Header';
import SideBar from 'component/Admin/SideBar';
import SideRouteList from 'data/Admin/SideRouteList';

const title = '머하짐 관리';
const mainPath = '/admin';

const BaseLayout = ({ children, searchState }) => {
  const baseSearchState = useState('');

  return (
    <div className="flex flex-row h-screen w-screen">
      <SideBar items={SideRouteList} mainPath={mainPath} title={title} />
      <div className="flex flex-col bg-white4">
        <Header
          mainPath={mainPath}
          title={title}
          needSearch={searchState ? true : false}
          searchState={searchState ? searchState : baseSearchState}
        />
        <div className="w-screen lg:w-[calc(100vw-theme(space.sidebar))] h-[calc(100vh-theme(space.header))] flex flex-col overflow-y-scroll scrollbar-hide">
          {children}
        </div>
      </div>
    </div>
  );
};

export default BaseLayout;
