import React, { Fragment, useEffect, useState } from 'react';
import SlideView from '../../component/SlideView/SlideView';
import { Map, MapMarker } from 'react-kakao-maps-sdk';
import Modal from '../../component/Modal/Modal';
import { Link, useParams } from 'react-router-dom';
import { API } from '../../api/API';
import { useSelector } from 'react-redux';

const SlideItem = ({ link, image, _id }) => {
  if (link)
    return (
      <a key={_id} href={link} className="w-full max-w-[40rem] m-auto flex">
        <img
          alt="slide item"
          src={image}
          className="w-full h-full object-contain"
        />
      </a>
    );
  else
    return (
      <div key={_id} className="w-full max-w-[40rem] m-auto flex">
        <img
          alt="slide item"
          src={image}
          className="w-full h-full object-contain"
        />
      </div>
    );
};

const dayName = ['월', '화', '수', '목', '금', '토', '일'];

const gymdummy = [
  {
    image: '/imgs/dummy/detail1.jpg',
    _id: '1',
  },
  {
    image: '/imgs/dummy/detail2.jpg',
    _id: '2',
  },
];

const Info = () => {
  const modalState = useState(false);
  const [, setModal] = modalState;
  const { id } = useParams();
  const { accessToken } = useSelector(state => state.userAuth);
  const [gym, setGym] = useState();
  const { data: gymData } = API.useGetGymDetails({
    gymId: id,
    accessToken: accessToken,
  });

  useEffect(() => {
    setModal(gym?.showNotice);
  }, [setModal, gym]);

  useEffect(() => {
    if (gymData?.success) {
      setGym(gymData?.data);
    }
  }, [gymData]);

  return (
    <>
      <div className="w-full">
        <SlideView
          renderer={SlideItem}
          // data={gym?.banners || []}
          data={gymdummy}
          dotSize={4}
        />
        {/* FIXME: dummy data */}
      </div>
      <div className="w-full max-w-[40rem] px-5 h-auto mt-5 mx-auto whitespace-pre-line">
        {gym?.showNotice && (
          <button
            className="bg-yellow-300 w-20 rounded-lg m-2"
            onClick={() => setModal(true)}
          >
            공지
          </button>
        )}
        <div className="flex justify-between gap-4">
          <div>
            <p className="text-[28px]/[35px] font-semibold mb-2">{gym?.name}</p>
            <p className="text-zinc-500 text-xs font-normal mb-2">
              {gym?.address} · {gym?.description}
            </p>
            <p className="text-sm/4 font-semibold">
              {gym?.dayPoint + gym?.gymFee}캐쉬/1회
            </p>
          </div>
          <Link to={`/reservation/${gym?._id}`}>
            <div className="w-[108px] h-11 px-4 py-3 bg-orange-600 rounded-xl justify-center items-center gap-1.5 inline-flex">
              <div className="w-5 h-5 justify-center items-center flex">
                <div className="w-5 h-5 p-[1.67px] justify-center items-center inline-flex">
                  <div className="w-[16.67px] h-[16.67px] relative">
                    <img
                      className="w-[16.67px] h-[16.67px] left-0 top-0 absolute"
                      src="/imgs/ic/ic-time.svg"
                    />
                  </div>
                </div>
              </div>
              <div className="text-white text-sm font-medium font-['Pretendard'] leading-tight tracking-wide">
                예약하기
              </div>
            </div>
          </Link>
        </div>
        <div className="mt-7">
          <p className="text-sm/4 font-bold mb-2">영업시간</p>
          <p className="text-sm/4 font-normal">
            {gym?.openDay &&
              [...gym.openDay]
                ?.map((v, i) => (v === '1' ? dayName[i] : ''))
                .join(' ')}
            <br />
            {`${gym?.openTime} - ${gym?.closeTime}`}
            {gym?.officialHoliday && (
              <>
                <br />
                공휴일 휴무
              </>
            )}
          </p>
        </div>
        <div className="mt-7">
          <p className="text-sm/4 font-bold mb-2">연락처</p>
          <p className="text-sm/4 font-normal underline">{gym?.phoneNumber}</p>
        </div>
        <div className="mt-7">
          <p className="text-sm/4 font-bold mb-2">오시는 길</p>
          <p className="text-sm/4 font-normal underline">
            경기 안양시 동안구 동편로 110
          </p>
        </div>
        {/* <p className="text-m font-semibold">{gym?.supplies}</p> */}
        {gym && (
          <Map
            center={{ lat: gym?.latitude, lng: gym?.longitude }}
            className="w-full max-w-[40rem] h-0 pb-[70%] mx-auto my-4 border-2 border-gray-400 rounded-xl"
          >
            <MapMarker position={{ lat: gym?.latitude, lng: gym?.longitude }} />
          </Map>
        )}
      </div>
      {gym?.showNotice && (
        <Modal modalState={modalState}>
          <div className="h-full w-full m-5 lg:m-10">
            <p className="text-2xl font-semibold">{gym.notice.title}</p>
            <p className="text-m font-semibold whitespace-pre-line">
              {gym.notice.content}
            </p>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Info;
