import { useEffect, useState } from 'react';
import { API } from 'api/API';
import NeedLoginLayout from 'layout/Admin/NeedLoginLayout';
import { Link } from 'react-router-dom';
import Selector from 'component/Admin/Selector';
import regionList from '../../data/regionList';
import { useSelector } from 'react-redux';
import { PlayIcon } from '@heroicons/react/24/outline';

const GymList = () => {
  const { accessToken } = useSelector(state => state.adminAuth);
  const [region, setRegion] = useState();
  const [city, setCity] = useState();

  const { data: cityData, isLoading: cityLoading } = API.useGetCities({
    regionCode: region?.value,
  });
  const {
    data: gymData,
    isLoading: gymLoading,
    refetch: refetchGym,
  } = API.useGetGyms({
    cityId: city?.value,
    regionCode: region?.value,
  });

  const { mutateAsync: deleteGym } = API.useDeleteGym();
  const { mutateAsync: changeGymOrder } = API.useChangeGymOrder();

  const onDelete = gymId => {
    if (window.confirm('정말 삭제하시겠습니까?')) {
      deleteGym({ accessToken, gymId }).then(res => {
        if (res?.success) {
          alert('삭제되었습니다.');
          refetchGym();
        } else {
          alert('삭제에 실패하였습니다.');
        }
      });
    }
  };

  const onChangeOrder = (gymId, direction) => {
    changeGymOrder({ accessToken, gymId, direction }).then(() => {
      refetchGym();
    });
  };

  useEffect(() => {
    if (region && !cityLoading && cityData?.data?.cities?.length === 0) {
      alert('해당 지역에 등록된 헬스장이 없습니다.');
      setRegion();
    }
  }, [cityLoading, region, cityData]);
  useEffect(() => {
    if (city && !gymLoading && gymData?.data?.gyms?.length === 0) {
      alert('해당 지역에 등록된 헬스장이 없습니다.');
      setCity();
    }
  }, [gymLoading, city, gymData]);

  return (
    <NeedLoginLayout>
      <div className="w-full flex flex-col p-4">
        <div className="h-20 w-full flex flex-row">
          <p className="text-3xl font-semibold text-gray-500">Gymlist</p>
        </div>
        <div className="w-full flex flex-row">
          <div className="flex flex-col">
            <p className="text-2xl mb-2 my-auto font-semibold text-gray-500">
              - 지역
            </p>
            <Selector
              value={region}
              items={regionList.map(v => ({
                value: v.label,
                label: v.label,
              }))}
              onSelect={v => {
                setRegion(v);
                setCity();
              }}
            />
          </div>
          <div className="flex flex-col ml-5">
            <p className="text-2xl mb-2 my-auto font-semibold text-gray-500">
              - 구
            </p>
            <Selector
              disabled={!region}
              value={city}
              items={cityData?.data?.cities?.map(v => ({
                value: v._id,
                label: v.name,
              }))}
              onSelect={v => {
                setCity(v);
              }}
            />
          </div>
        </div>
        <table className="w-full table-fixed mt-3">
          <thead>
            <tr className="h-12 border-2 text-2xl">
              <th className="w-1/12"></th>
              <th className="w-1/12">유형</th>
              <th className="w-2/12">카테고리</th>
              <th className="w-3/12">이름</th>
              <th className="w-3/12">주소</th>
              <th className="w-2/12">기능</th>
            </tr>
          </thead>
          <tbody>
            {gymData?.data?.gyms?.map((gym, index) => (
              <tr
                key={`${gym._id} ${index}`}
                className="text-xl h-20 border-2 group"
              >
                <td>
                  <div className="flex flex-col h-full justify-around">
                    <button
                      onClick={() => onChangeOrder(gym._id, 'up')}
                      className="w-6 mx-auto group-first:invisible"
                    >
                      <PlayIcon className="w-6 h-6 stroke-2 -rotate-90"></PlayIcon>
                    </button>
                    <button
                      onClick={() => onChangeOrder(gym._id, 'down')}
                      className="w-6 mx-auto group-last:invisible"
                    >
                      <PlayIcon className="w-6 h-6 stroke-2 rotate-90" />
                    </button>
                  </div>
                </td>
                <td className="text-center">
                  {gym?.type === 'free' ? '자율형' : '수업형'}
                </td>
                <td className="text-center">{gym?.category?.name}</td>
                <td className="text-center">{gym?.name}</td>
                <td className="text-center">{gym?.address}</td>
                <td>
                  <div className="flex flex-row place-content-center">
                    <Link
                      className="bg-blue-300 rounded-xl w-24 h-full text-center text-xl font-semibold text-blue-600"
                      to={`/admin/edit-gym/${gym._id}`}
                    >
                      상세정보
                    </Link>
                    <button
                      className="bg-red-300 rounded-xl w-24 h-full text-center text-xl font-semibold text-red-600 ml-2"
                      onClick={() => onDelete(gym._id)}
                    >
                      삭제
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </NeedLoginLayout>
  );
};

export default GymList;
