import Modal from 'component/Modal/Modal';

const SetPointModal = ({ point, setPoint, defaultPoint, onSubmit }) => {
  return (
    <Modal
      modalState={[point.open, v => setPoint(prev => ({ ...prev, open: v }))]}
      onClose={() => {
        setPoint({ ...defaultPoint, open: false });
      }}
    >
      <div className="p-10 flex flex-col bg-white w-screen">
        <div className="w-full flex flex-col col-span-2">
          <p className="text-2xl text-gray-500 font-semibold mb-2">- 포인트</p>
          <input
            type="number"
            disabled
            value={point.origin}
            className="w-full text-xl bg-transparent p-2 mb-2 resize-none"
          />
        </div>
        <div className="w-full flex flex-col col-span-2">
          <div className="flex mb-2">
            <p className="text-2xl flex h-full mx-2 w-2 place-content-center place-items-center">
              +
            </p>
            <input
              type="number"
              value={point.addPoint}
              onChange={e => {
                setPoint(prev => ({ ...prev, addPoint: e.target.value }));
              }}
              className="w-full border-2 text-xl bg-transparent border-gray-300 p-2 resize-none"
            />
          </div>
        </div>
        <div className="w-full flex flex-col col-span-2">
          <div className="flex mb-2">
            <p className="text-2xl flex h-full w-2 mx-2 place-content-center place-items-center">
              -
            </p>
            <input
              type="number"
              value={point.subPoint}
              onChange={e => {
                setPoint(prev => ({ ...prev, subPoint: e.target.value }));
              }}
              className="w-full border-2 text-xl bg-transparent border-gray-300 p-2 resize-none"
            />
          </div>
        </div>
        <div className="w-full flex flex-col col-span-2">
          <p className="text-2xl text-gray-500 font-semibold mb-2">- 사유</p>
          <textarea
            value={point.reason}
            onChange={e => {
              setPoint(prev => ({ ...prev, reason: e.target.value }));
            }}
            className="w-full h-[10rem] border-2 text-xl bg-transparent border-gray-300 p-2 mb-2 resize-none"
          />
        </div>
        <div className="w-full flex mt-5">
          <button
            onClick={() => {
              setPoint({ ...defaultPoint });
            }}
            className="flex-1 bg-gray-300 text-2xl text-gray-500 font-semibold py-2 rounded-xl"
          >
            취소
          </button>
          <button
            onClick={onSubmit}
            className="flex-1 bg-skyblue2 text-2xl text-white font-semibold py-2 rounded-xl ml-5"
          >
            확인
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default SetPointModal;
