import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Main from 'page/Main/Main';
import Info from 'page/Info/Info';
import BaseLayout from '../layout/BaseLayout/BaseLayout';
import List from 'page/List/List';
import Reservation from 'page/Reservation/Reservation';
import Login from 'page/Login/Login';
import MyPage from 'page/MyPage/MyPage';
import AdminRoute from './AdminRoute';
// import MyGym from 'page/MyGym/MyGym';
const AppRoute = () => {
  useEffect(() => {
    window.onbeforeunload = function pushRefresh() {
      window.scrollTo(0, 0);
    };
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<BaseLayout />}>
          <Route index element={<Main />} />
          <Route path="list/:region/:city" element={<List />} />
          <Route path="info/:id" element={<Info />} />
          <Route path="reservation/:id" element={<Reservation />} />
          <Route path="complete" element={<div>complete</div>} />
          <Route path="myreservation" element={<div>myreservation</div>} />
          <Route path="notice" element={<div>notice</div>} />
          <Route path="chargecash" element={<div>chargecash</div>} />
          <Route path="cashhistory" element={<div>cashhistory</div>} />
          <Route path="mypage" element={<MyPage />} />
          {/* <Route path="mygym/:id" element={<MyGym />} /> */}
          <Route path="*" element={<div>404</div>} />
        </Route>
        <Route path="login" element={<Login />} />
        <Route path="admin/*" element={<AdminRoute />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoute;
