import LoginCheck from 'component/Admin/LoginCheck';
import BaseLayout from 'layout/Admin/BaseLayout';

const NeedLoginLayout = ({ children, searchState }) => {
  return (
    <LoginCheck>
      <BaseLayout children={children} searchState={searchState} />
    </LoginCheck>
  );
};

export default NeedLoginLayout;
