import { API } from 'api/API';
import EditGymLayout from 'layout/Admin/EditGymLayout';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

const EditGymNotice = () => {
  const { accessToken } = useSelector(state => state.adminAuth);
  const { gymid } = useParams();
  const navigate = useNavigate();
  const [showNotice, setShowNotice] = useState(false);
  const [notice, setNotice] = useState({
    title: '',
    content: '',
  });

  const { data: gymData, refetch: refetchGym } = API.useGetGymDetails({
    gymId: gymid,
  });
  const { mutateAsync: createGymNotice } = API.useCreateGymNotice();

  useEffect(() => {
    if (gymData) {
      if (!gymData?.success) {
        alert('잘못된 접근입니다.');
        navigate(-1);
      } else {
        setShowNotice(gymData.data.showNotice);
        setNotice({
          title: gymData.data.notice?.title || '',
          content: gymData.data.notice?.content || '',
        });
      }
    }
  }, [gymData, navigate]);

  const onSubmit = () => {
    if (showNotice && notice.title === '') {
      alert('제목을 입력해주세요.');
    } else if (showNotice && notice.content === '') {
      alert('내용을 입력해주세요.');
    } else {
      createGymNotice({
        accessToken,
        gymId: gymid,
        title: notice.title,
        content: notice.content,
        showNotice,
      }).then(res => {
        if (res.success) {
          alert('공지가 수정되었습니다.');
          refetchGym();
        } else {
          alert('공지 수정에 실패하였습니다.');
        }
      });
    }
  };

  if (!gymData?.success) return null;
  return (
    <EditGymLayout gym={gymData.data}>
      <div className="p-10 flex flex-col">
        <div className="h-20 w-full flex flex-row">
          <p className="text-3xl font-semibold text-gray-500">
            * 체육관 공지 수정
          </p>
        </div>
        <div className="flex flex-col">
          <p className="text-2xl text-gray-500 font-semibold mb-2">
            - 공지 표시 여부
          </p>
          <div className="flex">
            <button
              onClick={() => {
                setShowNotice(prev => !prev);
              }}
              className={`text-xl border-2 rounded-xl text-center w-24 mr-5 ${
                showNotice
                  ? 'text-skyblue2 border-skyblue2'
                  : 'text-gray-300 border-gray-300'
              }`}
            >
              표시
            </button>
            <button
              onClick={() => {
                setShowNotice(prev => !prev);
              }}
              className={`text-xl border-2 rounded-xl text-center w-24 ${
                !showNotice
                  ? 'text-skyblue2 border-skyblue2'
                  : 'text-gray-300 border-gray-300'
              }`}
            >
              미표시
            </button>
          </div>
        </div>
        <div className="flex flex-col mt-10">
          <div className="flex mb-2">
            <p className="text-2xl text-gray-500 font-semibold">- 공지</p>
            <button
              className="text-xl rounded-xl ml-5 px-5 text-white bg-skyblue2"
              onClick={onSubmit}
            >
              수정
            </button>
          </div>
          <input
            disabled={!showNotice}
            type="text"
            className="border-2 border-gray-300 rounded-xl px-5 py-2 mt-2 w-96"
            placeholder="제목"
            value={notice.title}
            onChange={e => {
              setNotice(prev => ({ ...prev, title: e.target.value }));
            }}
          />
          <textarea
            disabled={!showNotice}
            className="border-2 border-gray-300 rounded-xl px-5 py-2 mt-2 resize-none h-96 w-96"
            placeholder="내용"
            value={notice.content}
            onChange={e => {
              setNotice(prev => ({ ...prev, content: e.target.value }));
            }}
          />
        </div>
      </div>
    </EditGymLayout>
  );
};

export default EditGymNotice;
