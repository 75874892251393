import React, { useState } from 'react';
import Header from 'component/Admin/Header';
import SideBar from 'component/Admin/SideBar';
import EditGymRouteList from 'data/Admin/EditGymRouteList';
import LoginCheck from 'component/Admin/LoginCheck';

const EditGymLayout = ({ children, searchState, gym }) => {
  const baseSearchState = useState('');
  const title = `${gym.name} 관리`;
  const mainPath = `/admin/edit-gym/${gym._id}`;

  return (
    <LoginCheck>
      <div className="flex flex-row h-screen w-screen">
        <SideBar
          items={EditGymRouteList(gym._id, gym.type)}
          mainPath={mainPath}
          title={title}
        />
        <div className="flex flex-col bg-white4">
          <Header
            mainPath={mainPath}
            title={title}
            needSearch={searchState ? true : false}
            searchState={searchState ? searchState : baseSearchState}
          />
          <div className="w-screen lg:w-[calc(100vw-theme(space.sidebar))] h-[calc(100vh-theme(space.header))] flex flex-col overflow-y-scroll scrollbar-hide">
            {children}
          </div>
        </div>
      </div>
    </LoginCheck>
  );
};

export default EditGymLayout;
