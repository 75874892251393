import { ajax } from './API';
import { useQuery, useMutation } from 'react-query';

const useGetBanners = () => {
  const options = {
    method: 'GET',
    url: '/banners',
  };
  return useQuery(['banners'], () => ajax(options));
};

const useGetNotions = () => {
  const options = {
    method: 'GET',
    url: '/notions',
  };
  return useQuery(['notions'], () => ajax(options));
};

const useCreateBanners = () => {
  return useMutation(({ accessToken, image, link }) =>
    ajax({
      method: 'POST',
      url: '/banner',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        image,
        link,
      },
    }),
  );
};

const useDeleteBanners = () => {
  return useMutation(({ accessToken, bannerId }) =>
    ajax({
      method: 'DELETE',
      url: `/banner/${bannerId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }),
  );
};

const useChangeBannersOrder = () => {
  return useMutation(({ accessToken, bannerId, direction }) =>
    ajax({
      method: 'PUT',
      url: `/banner/${bannerId}/change-order`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        direction,
      },
    }),
  );
};

const useCreateNotions = () => {
  return useMutation(({ accessToken, title, content }) =>
    ajax({
      method: 'POST',
      url: '/notion',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        title,
        content,
      },
    }),
  );
};

const useUpdateNotions = () => {
  return useMutation(({ accessToken, noticeId, title, content }) =>
    ajax({
      method: 'PUT',
      url: `/notion/${noticeId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        title,
        content,
      },
    }),
  );
};

const useDeleteNotions = () => {
  return useMutation(({ accessToken, noticeId }) =>
    ajax({
      method: 'DELETE',
      url: `/notion/${noticeId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }),
  );
};

const MainAPI = {
  useGetBanners,
  useGetNotions,
  useCreateBanners,
  useDeleteBanners,
  useChangeBannersOrder,
  useCreateNotions,
  useUpdateNotions,
  useDeleteNotions,
};
export default MainAPI;
