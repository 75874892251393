import { API } from 'api/API';
import EditGymLayout from 'layout/Admin/EditGymLayout';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const EditGymHoliday = () => {
  const { gymid } = useParams();
  const navigate = useNavigate();
  const [newHoliday, setNewHoliday] = useState({
    startDate: null,
    endDate: null,
    reason: '',
  });
  const [holidays, setHolidays] = useState([]);

  const { data: gymData, refetch: refetchGym } = API.useGetGymDetails({
    gymId: gymid,
  });
  const { mutateAsync: createHoliday } = API.useCreateGymHoliday();
  const { mutateAsync: deleteHoliday } = API.useDeleteGymHoliday();

  const onSubmit = () => {
    if (!newHoliday.startDate) {
      alert('시작 날짜를 입력해주세요.');
    } else if (!newHoliday.endDate) {
      alert('종료 날짜를 입력해주세요.');
    } else if (!newHoliday.reason) {
      alert('사유를 입력해주세요.');
    } else {
      let holidays = [];
      for (
        let i = new Date(newHoliday.startDate);
        i <= new Date(newHoliday.endDate);
        i.setDate(i.getDate() + 1)
      ) {
        holidays.push({
          date: i.toISOString().slice(0, 10),
          reason: newHoliday.reason,
        });
      }
      createHoliday({
        gymId: gymid,
        holidays,
      }).then(res => {
        if (res.success) {
          alert('휴무일이 추가되었습니다.');
          refetchGym();
        } else {
          alert('휴무일 추가에 실패하였습니다.');
        }
      });
    }
  };

  const onDelete = () => {
    const removeHolidays = holidays.filter(holiday => holiday.select);
    if (removeHolidays.length === 0) {
      alert('삭제할 휴무일을 선택해주세요.');
    } else {
      deleteHoliday({
        gymId: gymid,
        holidayIds: removeHolidays.map(holiday => holiday.holiday._id),
      }).then(res => {
        if (res.success) {
          alert('휴무일이 삭제되었습니다.');
          refetchGym();
        } else {
          alert('휴무일 삭제에 실패하였습니다.');
        }
      });
    }
  };

  useEffect(() => {
    if (gymData) {
      if (!gymData?.success) {
        alert('잘못된 접근입니다.');
        navigate(-1);
      } else {
        setHolidays(
          gymData.data.holidays.map(holiday => ({
            holiday: holiday,
            select: false,
          })),
        );
      }
    }
  }, [gymData, navigate]);

  if (!gymData?.success) return null;
  return (
    <EditGymLayout gym={gymData.data}>
      <div className="p-10 flex flex-col">
        <div className="h-20 w-full flex flex-row">
          <p className="text-3xl font-semibold text-gray-500">
            * 체육관 휴무일 수정
          </p>
        </div>
        <div className="flex flex-col">
          <p className="text-2xl text-gray-500 font-semibold mb-2">
            - 공휴일 휴무
          </p>
          <div className="flex">
            <div
              className={`text-xl border-2 rounded-xl text-center w-24 mr-5 ${
                gymData.data.officialHoliday
                  ? 'text-skyblue2 border-skyblue2'
                  : 'text-gray-300 border-gray-300'
              }`}
            >
              휴무
            </div>
            <div
              className={`text-xl border-2 rounded-xl text-center w-24 ${
                !gymData.data.officialHoliday
                  ? 'text-skyblue2 border-skyblue2'
                  : 'text-gray-300 border-gray-300'
              }`}
            >
              영업
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-10">
          <p className="text-2xl text-gray-500 font-semibold mb-2">
            - 휴무일 추가
          </p>
          <div className="flex">
            <input
              type="date"
              value={newHoliday.startDate}
              onChange={e =>
                setNewHoliday(prev => ({
                  ...prev,
                  startDate: e.target.value,
                }))
              }
              className="border-2 text-xl bg-transparent border-gray-300 p-2"
            />
            <p className="text-xl mx-3 my-auto">~</p>
            <input
              type="date"
              value={newHoliday.endDate}
              onChange={e =>
                setNewHoliday(prev => ({ ...prev, endDate: e.target.value }))
              }
              className="border-2 text-xl bg-transparent border-gray-300 p-2"
            />
            <input
              value={newHoliday.reason}
              onChange={e =>
                setNewHoliday(prev => ({ ...prev, reason: e.target.value }))
              }
              className="border-2 text-xl bg-transparent border-gray-300 p-2 ml-5"
            />
            <button
              className="bg-skyblue2 text-white text-xl rounded-xl ml-5 px-5"
              onClick={onSubmit}
            >
              추가
            </button>
          </div>
        </div>
        <div className="flex flex-col mt-10">
          <div className="flex mb-2">
            <p className="text-2xl text-gray-500 font-semibold">
              - 휴무일 목록
            </p>
            <button
              className="bg-red1 text-white text-xl rounded-xl ml-2 px-5"
              onClick={onDelete}
            >
              삭제
            </button>
          </div>
          <table className="table-auto flex flex-col">
            <thead className="mb-2">
              <tr className="border-2 border-gray-300">
                <th className="text-xl text-cente w-10" />
                <th className="text-xl text-center w-48">날짜</th>
                <th className="text-xl text-center w-2/3">사유</th>
              </tr>
            </thead>
            <tbody>
              {holidays.map(({ holiday, select }, index) => (
                <tr key={holiday._id} className="border-2 border-gray-300">
                  <td className="text-xl text-center w-10">
                    <input
                      checked={select}
                      onChange={e =>
                        setHolidays(prev => {
                          prev[index].select = e.target.checked;
                          return [...prev];
                        })
                      }
                      type="checkbox"
                    />
                  </td>
                  <td className="text-xl text-center w-48">{holiday.date}</td>
                  <td className="text-xl text-center w-2/3">
                    {holiday.reason}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </EditGymLayout>
  );
};

export default EditGymHoliday;
