import { createSlice } from '@reduxjs/toolkit';

export const userAuthSlice = createSlice({
  name: 'userAuth',
  initialState: {
    isLogin: false,
    accessToken: '',
    kakaoAuth: {},
  },
  reducers: {
    loginUser: (state, action) => {
      state.isLogin = true;
      state.kakaoAuth = action.payload.kakaoAuth;
      state.accessToken = action.payload.accessToken;
      return state;
    },
    clearUser: state => {
      state.isLogin = false;
      state.kakaoAuth = {};
      state.accessToken = '';
      return state;
    },
  },
});

export const { loginUser, clearUser } = userAuthSlice.actions;
