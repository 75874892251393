// import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
// import { Bars3Icon } from '@heroicons/react/24/outline';
// import { UserCircleIcon } from '@heroicons/react/24/outline';
// import Drawer from '../Drawer/Drawer';
// import { CurrencyDollarIcon } from '@heroicons/react/24/outline';
import { useSelector } from 'react-redux';
import { API } from 'api/API';

const Header = () => {
  // const [open, setOpen] = useState(false);
  const { accessToken } = useSelector(state => state.userAuth);

  const { data: me } = API.useGetMe({ accessToken });
  console.log(me?.data);

  return (
    <>
      <div className="mx-auto w-full h-16 max-w-[40rem] bg-white px-5 lg:px-0 flex items-center justify-between">
        <Link to="/">
          <img src="/imgs/logo.svg" alt="logo" />
        </Link>
        {/* <div className="h-full ml-auto flex felx-row place-items-center">
          <Link to={'/mypage'} className="flex flex-row">
            <UserCircleIcon className="w-8 h-8 my-auto" />
          </Link>
          {me?.data?.point >= 0 && (
            <>
              <CurrencyDollarIcon className="w-8 h-8 my-auto" />
              <p className="text-xl">{me?.data?.point}</p>
            </>
          )}
          <button className="ml-3 flex flex-row" onClick={() => setOpen(true)}>
            <Bars3Icon className="w-8 h-8 my-auto" />
          </button>
        </div> */}
        <div className="flex gap-1.5 items-start">
          <img src="/imgs/ic/ic-cash.svg" alt="cash" />
          <p className="text-xs font-bold">120,000</p> {/* 임시 */}
        </div>
      </div>
      {/* <Drawer open={open} setOpen={setOpen} /> */}
    </>
  );
};

export default Header;
