const regionList = [
  {
    id: 1,
    label: '서울',
  },
  {
    id: 2,
    label: '경기',
  },
  {
    id: 3,
    label: '인천',
  },
  {
    id: 4,
    label: '강원',
  },
  {
    id: 5,
    label: '대전',
  },
  {
    id: 6,
    label: '세종',
  },
  {
    id: 7,
    label: '충북',
  },
  {
    id: 8,
    label: '충남',
  },
  {
    id: 9,
    label: '부산',
  },
  {
    id: 10,
    label: '전북',
  },
  {
    id: 11,
    label: '제주',
  },
  {
    id: 12,
    label: '경남',
  },
];

export default regionList;
