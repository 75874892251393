import { useState } from 'react';
import useLoginCheck from '../../hooks/UseLoginCheck';
import BaseLayout from '../../layout/BaseLayout/BaseLayout';
import { API } from '../../api/API';
import { useDispatch, useSelector } from 'react-redux';
import ShopModal from '../../component/ShopModal/ShopModal';
import CancelModal from './CancelModal';
import { clearUser } from 'store/userAuthSlice';
import useKakaoLogin from 'hooks/UseKakaoLogin';
import { Link } from 'react-router-dom';

const reservationStatus = {
  wait: {
    name: '대기',
    btn: {
      disabled: false,
      label: '취소 신청',
      style: 'border-red1 text-red1',
    },
  },
  cancel: {
    name: '취소',
    btn: {
      disabled: true,
      label: '취소 완료',
      style: 'border-skyblue2 text-skyblue2',
    },
  },
  end: {
    name: '만료',
    btn: {
      disabled: false,
      label: '취소 신청',
      style: 'border-red1 text-red1',
    },
  },
  cancel_wait: {
    name: '취소 대기',
    btn: {
      disabled: true,
      label: '취소 대기',
      style: 'border-yellow2 text-yellow2',
    },
  },
};

const defaultCancel = {
  open: false,
  reservationId: null,
  reason: '',
};

const MyPage = () => {
  useLoginCheck();
  const { accessToken } = useSelector(state => state.userAuth);
  const dispatch = useDispatch();
  const kakaoLogin = useKakaoLogin();

  const shopState = useState(false);
  const [, setShop] = shopState;

  const [cancel, setCancel] = useState({ ...defaultCancel });

  const { data: meData } = API.useGetMe({
    accessToken: accessToken,
  });
  const { mutateAsync: cancelReservation } = API.useCancelReservation();

  const onLogout = () => {
    dispatch(clearUser());
    kakaoLogin.logout();
  };

  const onCancelReservation = () => {
    if (cancel.reason === '') {
      alert('취소 사유를 입력해주세요.');
    } else if (cancel.reservationId === null) {
      alert('예약 정보가 없습니다.');
    } else {
      cancelReservation({
        accessToken: accessToken,
        reservationId: cancel.reservationId,
        reason: cancel.reason,
      }).then(res => {
        if (res.success) {
          alert('취소 신청이 완료되었습니다.');
        } else {
          alert('취소 신청에 실패하였습니다.');
        }
        setCancel({ ...defaultCancel });
      });
    }
  };

  return (
    <BaseLayout>
      <div className="w-full max-w-[40rem] flex flex-col px-2 lg:px-0 mx-auto">
        <div className="border-b-2 flex w-full my-5 pb-3 text-3xl">
          어서오세요<p className="px-5 font-bold">{meData?.data?.name}</p>님
          <button
            onClick={onLogout}
            className="border-2 border-red1 rounded-xl text-red1 text-xl px-2 ml-auto whitespace-nowrap"
          >
            로그아웃
          </button>
        </div>
        <div className="w-full grid grid-rows-2 lg:grid-cols-2 gap-y-10 lg:gap-x-10 place-items-start pb-10">
          <div className="flex w-full place-content-center place-items-center">
            <p className="text-2xl mr-3 whitespace-nowrap">이름</p>
            <p className="px-3 bg-white2 text-xl w-full">
              {meData?.data?.name}
            </p>
          </div>
          <div className="flex w-full place-content-center place-items-center">
            <p className="text-2xl whitespace-nowrap">보유 캐쉬</p>
            <p className="px-3 ml-3 bg-white2 text-xl w-full">
              {meData?.data?.point}
            </p>
            <button
              onClick={() => setShop(true)}
              className="bg-green-300 text-xl px-2 ml-2 whitespace-nowrap"
            >
              캐쉬 충전
            </button>
          </div>
        </div>
        <p className="border-b-2 flex w-full my-5 pb-3 text-2xl">
          관리 체육관 목록
        </p>
        <div className="flex w-full max-h-60 overflow-y-scroll scrollbar-show">
          {[
            ...(meData?.data?.masterGyms || []),
            ...(meData?.data?.managerGyms || []),
          ]?.map(gym => (
            <Link
              className="w-full h-10 flex place-content-center place-items-center border-gray-300 border-2"
              to={`/mygym/${gym._id}`}
            >
              {gym.name}
            </Link>
          ))}
        </div>
        <p className="border-b-2 flex w-full my-5 pb-3 text-2xl">예약내역</p>
        <div className="flex w-full max-h-60 overflow-y-scroll scrollbar-show">
          <table className="table-fixed w-full">
            <thead>
              <tr className="border-2 border-gray-300">
                <th className="text-xl text-center w-2/6">체육관 이름</th>
                <th className="text-xl text-center w-1/6">비용</th>
                <th className="text-xl text-center w-2/6">상태</th>
                <th className="text-xl text-center w-1/6"></th>
              </tr>
            </thead>
            <tbody>
              <tr className="h-2" />
              {meData?.data?.reservations?.map(reservation => (
                <tr
                  key={reservation._id}
                  className="border-2 border-gray-300 h-16"
                >
                  <td className="lg:text-xl text-center">
                    {reservation.gymName}
                  </td>
                  <td className="lg:text-xl text-center">
                    {reservation.point}
                  </td>
                  <td className="lg:text-xl text-center">
                    {reservationStatus[reservation.status].name}
                  </td>
                  <td className="lg:text-xl text-center flex h-16 place-content-center place-items-center">
                    <button
                      disabled={
                        reservationStatus[reservation.status].btn.disabled
                      }
                      onClick={() => {
                        setCancel({
                          open: true,
                          reservationId: reservation._id,
                          reason: '',
                        });
                      }}
                      className={`h-10 text-sm lg:text-md rounded-xl w-16 lg:w-24 border-2 ${
                        reservationStatus[reservation.status].btn.style
                      }`}
                    >
                      {reservationStatus[reservation.status].btn.label}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <p className="border-b-2 flex w-full my-5 pb-3 text-2xl">
          캐쉬 사용내역
        </p>
        <div className="flex w-full max-h-60 overflow-y-scroll scrollbar-show">
          <table className="table-fixed w-full">
            <thead>
              <tr className="border-2 border-gray-300">
                <th className="text-xl text-center w-2/4">사유</th>
                <th className="text-xl text-center w-1/4">포인트</th>
                <th className="text-xl text-center w-1/4">누적</th>
              </tr>
            </thead>
            <tbody>
              <tr className="h-2" />
              {meData?.data?.pointHistories
                ?.filter(p => p.point !== null)
                .map(pointHistory => (
                  <tr
                    key={pointHistory._id}
                    className="border-2 border-gray-300 h-16"
                  >
                    <td className="lg:text-xl text-center">
                      {pointHistory.reason}
                    </td>
                    <td className="lg:text-xl text-center">
                      {pointHistory.point}
                    </td>
                    <td className="lg:text-xl text-center">
                      {pointHistory.chargePoint}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <p className="border-b-2 flex w-full my-5 pb-3 text-2xl">
          예약 취소 신청 내역
        </p>
        <div className="flex w-full max-h-60 overflow-y-scroll scrollbar-show mb-5">
          <table className="table-fixed w-full">
            <thead>
              <tr className="border-2 border-gray-300">
                <th className="text-xl text-center w-1/2">체육관 이름</th>
                <th className="text-xl text-center w-1/2">사유</th>
              </tr>
            </thead>
            <tbody>
              <tr className="h-2" />
              {meData?.data?.cancelReservations?.map(cancel => (
                <tr key={cancel._id} className="border-2 border-gray-300 h-16">
                  <td className="lg:text-xl text-center">{cancel.gymName}</td>
                  <td className="lg:text-xl text-center">{cancel.reason}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <p className="ml-auto mt-auto">환불문의 : 010-0000-0000</p>
      </div>
      <ShopModal modalState={shopState} />
      <CancelModal
        cancel={cancel}
        setCancel={setCancel}
        defaultCancel={defaultCancel}
        onSubmit={onCancelReservation}
      />
    </BaseLayout>
  );
};

export default MyPage;
