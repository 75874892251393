const { kakao } = window;

export const getPosFromAddress = async (
  address,
  onFound = (lat, lng) => {
    console.log({ lat, lng });
  },
) => {
  const geocoder = new kakao.maps.services.Geocoder();
  const callback = (result, status) => {
    if (status === kakao.maps.services.Status.OK) {
      onFound(parseFloat(result[0].y), parseFloat(result[0].x));
    } else {
      onFound(null, null);
    }
  };
  await geocoder.addressSearch(address, callback);
};
