import { useQuery, useMutation } from 'react-query';
import { ajax } from './API';

const useGetCities = ({ regionCode }) => {
  const options = {
    method: 'GET',
    url: `/city/${regionCode}`,
  };
  return useQuery(['cities', regionCode], () => ajax(options));
};

const useCreateCity = () => {
  return useMutation(({ accessToken, regionCode, name }) =>
    ajax({
      method: 'POST',
      url: `/city/${regionCode}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        name,
      },
    }),
  );
};

const CityAPI = {
  useGetCities,
  useCreateCity,
};
export default CityAPI;
