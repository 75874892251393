import { API } from 'api/API';
import BaseLayout from 'layout/Admin/BaseLayout';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { loginAdmin } from 'store/adminAuthSlice';

const Login = () => {
  const adminAuth = useSelector(state => state.adminAuth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const nextLink = location.state?.nextLink;
  const [id, setId] = useState('');
  const [pwd, setPwd] = useState('');

  const { mutateAsync: adminLogin } = API.useAdminLogin();

  const goNext = () => {
    if (nextLink) navigate(nextLink, { replace: true });
    else navigate('/admin', { replace: true });
  };

  const login = () => {
    adminLogin({ adminId: id, adminPassword: pwd }).then(res => {
      if (res.success) {
        dispatch(
          loginAdmin({
            accessToken: res.data.accessToken,
          }),
        );
        goNext();
      }
    });
  };

  useEffect(() => {
    if (adminAuth?.isLogin) {
      goNext();
    }
  });

  return (
    <BaseLayout>
      <div className="w-full h-full flex flex-col p-4">
        <div className="flex flex-col mx-auto mt-20">
          <div className="h-20 w-full flex flex-row">
            <p className="text-3xl font-semibold text-gray-500">Login</p>
          </div>
          <input
            value={id}
            onChange={e => setId(e.target.value)}
            placeholder="아이디"
            className="border-2 rounded-xl text-xl p-2 mb-5 w-60"
          />
          <input
            value={pwd}
            onChange={e => setPwd(e.target.value)}
            placeholder="비밀번호"
            type="password"
            className="border-2 rounded-xl text-xl p-2 w-60"
          />
          <button
            onClick={login}
            className="bg-skyblue1 text-white p-2 rounded-xl mt-5 w-60"
          >
            Login
          </button>
        </div>
      </div>
    </BaseLayout>
  );
};

export default Login;
