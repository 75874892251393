const gymValidator = [
  {
    key: 'type',
    message: '체육관 타입을 선택해주세요',
    required: true,
  },
  {
    key: 'category',
    message: '체육관 카테고리를 선택해주세요',
    required: true,
  },
  {
    key: 'address',
    message: '주소를 입력해주세요',
    required: true,
  },
  {
    key: 'latitude',
    message: '주소로 위도를 찾을 수 없습니다.',
    required: true,
  },
  {
    key: 'longitude',
    message: '주소로 경도를 찾을 수 없습니다.',
    required: true,
  },
  {
    key: 'region',
    message: '지역을 입력해주세요',
    required: true,
  },
  {
    key: 'city',
    message: '시를 입력해주세요',
    required: true,
  },
  {
    key: 'name',
    message: '이름을 입력해주세요',
    required: true,
  },
  {
    key: 'description',
    message: '설명을 입력해주세요',
    required: true,
  },
  {
    key: 'phoneNumber',
    message: '전화번호를 입력해주세요',
    required: true,
  },
  {
    key: 'master',
    message: '대표자를 입력해주세요',
    required: true,
  },
  {
    key: 'manager',
    message: '담당자를 입력해주세요',
    required: false,
  },
  {
    key: 'supplies',
    message: '준비물을 입력해주세요',
    required: true,
  },
  {
    key: 'officialHoliday',
    message: '공휴일 휴무 여부를 입력해주세요',
    required: true,
  },
  {
    key: 'openTime',
    message: '운영시간을 입력해주세요',
    required: true,
  },
  {
    key: 'closeTime',
    message: '운영시간을 입력해주세요',
    required: true,
  },
  {
    key: 'openDay',
    message: '운영요일을 입력해주세요',
    required: true,
  },
  {
    key: 'dayPoint',
    message: '이용 가격을 입력해주세요',
    required: true,
  },
  {
    key: 'gymFee',
    message: '이용 수수료를 입력해주세요',
    required: true,
  },
];

export const freeGymValidator = [...gymValidator];

export const classGymValidator = [...gymValidator];

export const courseValidator = [
  {
    key: 'name',
    message: '강좌 이름을 입력해주세요',
    required: true,
  },
  {
    key: 'day',
    message: '강좌 요일을 입력해주세요',
    required: true,
  },
  {
    key: 'start',
    message: '강좌 시작 시간을 입력해주세요',
    required: true,
  },
  {
    key: 'time',
    message: '강좌 소요 시간을 입력해주세요',
    required: true,
  },
  {
    key: 'maxPeople',
    message: '강좌 최대 인원을 입력해주세요',
    required: true,
  },
  {
    key: 'offlinePeople',
    message: '강좌 오프라인 인원을 입력해주세요',
    required: true,
  },
];

export const reservationValidator = [
  {
    key: 'date',
    message: '날짜를 선택해주세요',
    required: true,
  },
  {
    key: 'peopleNum',
    message: '인원을 선택해주세요',
    required: true,
  },
  {
    key: 'refund',
    message: '환불 규정에 동의해주세요',
    required: true,
  },
];

export const freeReservationValidator = [
  ...reservationValidator,
  {
    key: 'startAt',
    message: '시간을 선택해주세요',
    required: true,
  },
];

export const classReservationValidator = [
  ...reservationValidator,
  {
    key: 'course',
    message: '강좌를 선택해주세요',
    required: true,
  },
];

export const validateChecker = (data, validator) => {
  for (const v of validator) {
    if (
      v.required &&
      (data[v.key] === undefined || data[v.key] === '' || data[v.key] === null)
    ) {
      return { success: false, message: v.message };
    }
  }
  return { success: true, message: 'success' };
};
