import Modal from 'component/Modal/Modal';

const dayName = ['월', '화', '수', '목', '금', '토', '일'];

const EditGymCourseModal = ({ modalState, defaultCourse }) => {
  const [modal, setModal] = modalState;

  return (
    <Modal
      modalState={[modal.open, v => setModal(prev => ({ ...prev, open: v }))]}
      onClose={() => {
        setModal({ ...defaultCourse, open: false });
      }}
    >
      <div className="p-10 flex flex-col bg-white w-screen">
        <div className="h-20 w-full flex flex-row">
          <p className="text-3xl font-semibold text-gray-500">* 체육관 강좌</p>
        </div>
        <div className="w-full flex flex-col col-span-2">
          <p className="text-2xl text-gray-500 font-semibold mb-2">- 요일</p>
          <div className="grid grid-cols-7 gap-x-5">
            {dayName.map((v, i) => (
              <button
                onClick={() => {
                  setModal(prev => {
                    const day = prev.day;
                    day[i] = day[i] === 0 ? 1 : 0;
                    return { ...prev, day };
                  });
                }}
                key={i}
                className={`text-xl border-2 rounded-xl ${
                  modal.day[i]
                    ? 'text-skyblue2 border-skyblue2'
                    : 'text-gray-300 border-gray-300'
                }`}
              >
                {v}
              </button>
            ))}
          </div>
        </div>
        <div className="w-full flex flex-col mt-5">
          <p className="text-2xl text-gray-500 font-semibold mb-2">
            - 시작 시간
          </p>
          <input
            value={modal.start}
            onChange={e => {
              setModal(prev => ({ ...prev, start: e.target.value }));
            }}
            type="time"
            className="border-2 text-xl bg-transparent border-gray-300 p-2 mb-2"
          />
        </div>
        <div className="w-full flex flex-col mt-5">
          <p className="text-2xl text-gray-500 font-semibold mb-2">
            - 소요 시간
          </p>
          <div className="flex w-full">
            <input
              value={modal.time.hour}
              onChange={e => {
                setModal(prev => ({
                  ...prev,
                  time: {
                    ...prev.time,
                    hour: e.target.value > 0 ? e.target.value : 0,
                  },
                }));
              }}
              type="number"
              className="flex w-20 text-center border-2 text-xl bg-transparent border-gray-300 p-2"
            />
            <p className="mx-5 text-xl flex place-content-center place-items-center">
              시간
            </p>
            <input
              value={modal.time.minute}
              onChange={e => {
                setModal(prev => ({
                  ...prev,
                  time: {
                    ...prev.time,
                    minute: e.target.value > 0 ? e.target.value : 0,
                  },
                }));
              }}
              type="number"
              className="flex w-20 text-center border-2 text-xl bg-transparent border-gray-300 p-2"
            />
            <p className="ml-5 text-xl flex place-content-center place-items-center">
              분
            </p>
          </div>
        </div>
        <div className="w-full flex flex-col mt-5">
          <p className="text-2xl text-gray-500 font-semibold mb-2">- 이름</p>
          <input
            value={modal.name}
            onChange={e => {
              setModal(prev => ({ ...prev, name: e.target.value }));
            }}
            className="border-2 text-xl bg-transparent border-gray-300 p-2 mb-2"
          />
        </div>
        <div className="w-full flex flex-col mt-5">
          <p className="text-2xl text-gray-500 font-semibold mb-2">- 인원</p>
          <div className="flex w-full">
            <input
              value={modal.offlinePeople}
              onChange={e => {
                setModal(prev => ({
                  ...prev,
                  offlinePeople: e.target.value > 0 ? e.target.value : 0,
                }));
              }}
              type="number"
              className="flex w-20 text-center border-2 text-xl bg-transparent border-gray-300 p-2"
            />
            <p className="mx-5 text-xl flex place-content-center place-items-center">
              /
            </p>
            <input
              value={modal.maxPeople}
              onChange={e => {
                setModal(prev => ({
                  ...prev,
                  maxPeople: e.target.value > 0 ? e.target.value : 0,
                }));
              }}
              type="number"
              className="flex w-20 text-center border-2 text-xl bg-transparent border-gray-300 p-2"
            />
          </div>
        </div>
        <div className="w-full flex mt-5">
          <button
            onClick={() => {
              setModal({ ...defaultCourse });
            }}
            className="flex-1 bg-gray-300 text-2xl text-gray-500 font-semibold py-2 rounded-xl"
          >
            취소
          </button>
          <button
            onClick={() => modal.onSubmit(modal)}
            className="flex-1 bg-skyblue2 text-2xl text-white font-semibold py-2 rounded-xl ml-5"
          >
            확인
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default EditGymCourseModal;
