import BaseLayout from 'layout/Admin/BaseLayout';

const PageNotFound = () => {
  return (
    <BaseLayout>
      <div className="w-full h-full flex">
        <p className="text-red1 text-3xl m-auto">Page Not Found</p>
      </div>
    </BaseLayout>
  );
};

export default PageNotFound;
