import {
  UserGroupIcon,
  MapIcon,
  MapPinIcon,
  FilmIcon,
  InformationCircleIcon,
  ChartPieIcon,
  ArchiveBoxXMarkIcon,
} from '@heroicons/react/24/outline';

const SideRouteList = [
  {
    id: 'user-list',
    Icon: ({ className }) => <UserGroupIcon className={className} />,
    title: '사용자 목록',
    link: '/admin/user-list',
  },
  {
    id: 'gym-list',
    Icon: ({ className }) => <MapIcon className={className} />,
    title: '체육관 목록',
    link: '/admin/gym-list',
  },
  {
    id: 'add-gym',
    Icon: ({ className }) => <MapPinIcon className={className} />,
    title: '체육관 추가',
    link: '/admin/add-gym',
  },
  {
    id: 'edit-main-banner',
    Icon: ({ className }) => <FilmIcon className={className} />,
    title: '메인 배너 수정',
    link: '/admin/edit-main-banner',
  },
  {
    id: 'edit-main-notice',
    Icon: ({ className }) => <InformationCircleIcon className={className} />,
    title: '메인 공지 수정',
    link: '/admin/edit-main-notice',
  },
  // {
  //   id: "add-official-holiday",
  //   Icon: ({ className }) => <CalendarDaysIcon className={className} />,
  //   title: "공휴일 추가",
  //   link: "/admin/add-official-holiday",
  // },
  {
    id: 'statistics',
    Icon: ({ className }) => <ChartPieIcon className={className} />,
    title: '통계',
    link: '/admin/statistics',
  },
  {
    id: 'cancel-reservation',
    Icon: ({ className }) => <ArchiveBoxXMarkIcon className={className} />,
    title: '예약 취소 관리',
    link: '/admin/cancel-reservation',
  },
];

export default SideRouteList;
