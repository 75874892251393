import { ajax } from './API';
import { useMutation, useQuery } from 'react-query';

const useLogin = () => {
  return useMutation(({ code }) =>
    ajax({
      method: 'POST',
      url: '/users/login',
      data: {
        code,
      },
    }),
  );
};

const useGetMe = ({ accessToken }) => {
  const options = {
    method: 'GET',
    url: '/users/me',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return useQuery(['me', accessToken], () => ajax(options));
};

const useCancelReservation = () => {
  return useMutation(({ accessToken, reservationId, reason }) =>
    ajax({
      method: 'POST',
      url: `/reservations/${reservationId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        reason,
      },
    }),
  );
};

const UserAPI = {
  useLogin,
  useGetMe,
  useCancelReservation,
};
export default UserAPI;
