import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

const useLoginCheck = (needLogin = true, redirect = null) => {
  const { isLogin } = useSelector(state => state.userAuth);
  const navigate = useNavigate();
  const location = useLocation();
  const afterCheck = useCallback(
    check => {
      if (needLogin && !check) {
        navigate('/login', {
          replace: true,
          state: { nextLink: redirect || location.pathname },
        });
      } else if (!needLogin && check) {
        navigate(redirect || '/', {
          replace: true,
          state: { nextLink: redirect || location.pathname },
        });
      }
    },
    [navigate, needLogin, redirect, location.pathname],
  );
  useEffect(() => {
    afterCheck(isLogin);
  }, [isLogin, afterCheck]);
  return isLogin;
};

export default useLoginCheck;
