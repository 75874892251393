import { useMutation } from 'react-query';
import { ajax } from './API';
const useMediaUpload = () => {
  return useMutation(({ accessToken, file, mediaId }) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('mediaId', mediaId);
    return ajax({
      method: 'POST',
      url: '/media',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    });
  });
};

const MediaAPI = {
  useMediaUpload,
};

export default MediaAPI;
