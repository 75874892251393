import { useSelector } from 'react-redux';

export const NoLogin = ({ children }) => {
  const adminAuth = useSelector(state => state.adminAuth);
  return adminAuth?.isLogin ? null : children;
};

export const NeedLogin = ({ children }) => {
  const adminAuth = useSelector(state => state.adminAuth);
  return adminAuth?.isLogin ? children : null;
};

const AdminQueries = { NoLogin, NeedLogin };

export default AdminQueries;
