import { useQuery, useMutation } from 'react-query';
import { ajax } from './API';

const useGetCourses = ({ gymId }) => {
  const options = {
    method: 'GET',
    url: `/course/${gymId}`,
  };
  return useQuery(['courses', gymId], () => ajax(options));
};

const useCreateGymCourse = () => {
  return useMutation(
    ({
      accessToken,
      gymId,
      start,
      time,
      day,
      name,
      maxPeople,
      offlinePeople,
    }) =>
      ajax({
        method: 'POST',
        url: `/course/${gymId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          start,
          time,
          day,
          name,
          maxPeople,
          offlinePeople,
        },
      }),
  );
};

const useDeleteGymCourse = () => {
  return useMutation(({ accessToken, courseId }) =>
    ajax({
      method: 'DELETE',
      url: `/course/${courseId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }),
  );
};

const useUpdateGymCourse = () => {
  return useMutation(
    ({
      courseId,
      accessToken,
      start,
      time,
      day,
      name,
      maxPeople,
      offlinePeople,
    }) =>
      ajax({
        method: 'PUT',
        url: `/course/${courseId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          start,
          time,
          day,
          name,
          maxPeople,
          offlinePeople,
        },
      }),
  );
};

const CourseAPI = {
  useGetCourses,
  useCreateGymCourse,
  useDeleteGymCourse,
  useUpdateGymCourse,
};

export default CourseAPI;
