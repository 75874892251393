import CreatableSelect from 'react-select/creatable';

const CreatableSelector = ({
  className,
  value = null,
  onSelect = () => {},
  onCreate = () => {},
  items,
  disabled = false,
}) => {
  return (
    <CreatableSelect
      className={className}
      isDisabled={disabled}
      styles={{
        control: provided => ({
          ...provided,
          border: '2px solid rgb(209 213 219)',
          marginBottom: '0.5rem',
          padding: '0.1rem',
          fontSize: '1.25rem',
          lineHeight: '1.75rem',
          backgroundColor: 'transparent',
          borderRadius: '0',
        }),
      }}
      placeholder="-----"
      value={value}
      options={items}
      onChange={onSelect}
      onCreateOption={onCreate}
    />
  );
};

export default CreatableSelector;
