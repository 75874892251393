import { API } from 'api/API';
import NeedLoginLayout from 'layout/Admin/NeedLoginLayout';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const CancelReservation = () => {
  const { accessToken } = useSelector(state => state.adminAuth);

  const { data: cancelReservationData, refetch: cancelReservationRefetch } =
    API.useGetCancelReservations({
      accessToken,
    });

  const { mutateAsync: acceptCancel } = API.useAcceptCancelReservation();

  const onAccpet = (cancelReservationId, accept) => {
    acceptCancel({ accessToken, cancelReservationId, accept }).then(res => {
      if (!res.success) {
        alert('오류가 발생했습니다.');
      } else {
        cancelReservationRefetch();
      }
    });
  };

  return (
    <NeedLoginLayout>
      <div className="w-full flex flex-col p-4">
        <div className="h-20 w-full flex flex-row">
          <p className="text-3xl font-semibold text-gray-500">예약 취소 관리</p>
        </div>
        <table className="w-full table-fixed mt-3">
          <thead>
            <tr className="h-12 border-2 text-2xl">
              <th className="w-2/12">유저 이름</th>
              <th className="w-2/12">체육관 이름</th>
              <th className="w-4/12">사유</th>
              <th className="w-1/12">예약 상태</th>
              <th className="w-3/12">기능</th>
            </tr>
          </thead>
          <tbody>
            {cancelReservationData?.data?.map(cancel => (
              <tr key={cancel._id} className="text-xl h-20 border-2 group">
                <td className="text-center">
                  <Link
                    to={`/admin/detailed-users/${cancel.reservation.user._id}`}
                    className="hover:underline"
                  >
                    {cancel.reservation.user.name}
                  </Link>
                </td>
                <td className="text-center">
                  <Link
                    to={`/admin/edit-gym/${cancel.reservation.gym._id}`}
                    className="hover:underline"
                  >
                    {cancel.reservation.gym.name}
                  </Link>
                </td>
                <td className="text-center">{cancel.reason}</td>
                <td className="text-center">
                  {cancel.createdAt < cancel.reservation.startAt
                    ? '취소 시간 전'
                    : '취소 시간 후'}
                </td>
                <td>
                  <div className="flex flex-row place-content-center">
                    <button
                      className="bg-green-300 rounded-xl w-24 h-full text-center text-xl font-semibold text-green-600"
                      onClick={() => {
                        onAccpet(cancel._id, true);
                      }}
                    >
                      승인
                    </button>
                    <button
                      className="bg-red-300 rounded-xl w-24 h-full text-center text-xl font-semibold text-red-600 ml-2"
                      onClick={() => {
                        onAccpet(cancel._id, false);
                      }}
                    >
                      거절
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </NeedLoginLayout>
  );
};

export default CancelReservation;
