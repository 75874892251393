import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { API } from '../../api/API';
import useKakaoLogin from '../../hooks/UseKakaoLogin';
import useLoginCheck from '../../hooks/UseLoginCheck';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { loginUser } from '../../store/userAuthSlice';

const Login = () => {
  const location = useLocation();
  const [search] = useSearchParams();
  const code = search?.get('code');
  const redirect = search?.get('state');
  const nextLink = location.state?.nextLink || redirect || '/';
  const kakao = useKakaoLogin(nextLink);
  const dispath = useDispatch();
  const navigate = useNavigate();
  useLoginCheck(false, nextLink);
  const { mutateAsync: login } = API.useLogin();

  useEffect(() => {
    if (code && dispath && navigate && nextLink) {
      login({ code }).then(res => {
        if (res.success) {
          dispath(
            loginUser({
              accessToken: res.data.accessToken,
            }),
          );
          navigate(redirect, { replace: true });
        }
      });
    }
  }, [dispath, navigate, nextLink, login, code, redirect]);

  return (
    <div className="w-full h-screen flex max-lg:flex-col">
      <div className="w-full lg:w-1/3 h-full flex">
        <img alt="slide" src="imgs/slide4.png" className="object-cover" />
      </div>
      <div className="w-full lg:w-2/3 h-full flex place-items-center flex-col bg-white2">
        <h1 className="text-6xl mt-32 font-bold">머하짐</h1>
        <button className="my-auto max-lg:px-2" onClick={kakao.login}>
          <img alt="kakao login" src="imgs/kakao_login.png" />
        </button>
      </div>
    </div>
  );
};

export default Login;
