import { Route, Routes } from 'react-router-dom';
import EditGymMain from 'page/EditGym/EditGymMain';
import EditGymInfo from 'page/EditGym/EditGymInfo';
import EditGymNotice from 'page/EditGym/EditGymNotice';
import EditGymBanner from 'page/EditGym/EditGymBanner';
import EditGymHoliday from 'page/EditGym/EditGymHoliday';
import EditGymCourse from 'page/EditGym/EditGymCourse';
import EditGymReservation from 'page/EditGym/EditGymReservation';

const EditGymRoute = () => {
  return (
    <Routes>
      <Route index element={<EditGymMain />} />
      <Route path="info" element={<EditGymInfo />} />
      <Route path="notice" element={<EditGymNotice />} />
      <Route path="banner" element={<EditGymBanner />} />
      <Route path="holiday" element={<EditGymHoliday />} />
      <Route path="course" element={<EditGymCourse />} />
      <Route path="reservation" element={<EditGymReservation />} />
    </Routes>
  );
};

export default EditGymRoute;
