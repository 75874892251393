import React from 'react';
import { API, IMP } from '../../api/API';
import { useSelector } from 'react-redux';
import { CurrencyDollarIcon } from '@heroicons/react/24/outline';
import Modal from '../Modal/Modal';
import { v4 as uuid } from 'uuid';

const pointList = [100, 1000, 3000, 5000, 10000, 50000, 100000];

const ShopModal = ({ modalState }) => {
  const { accessToken } = useSelector(state => state.userAuth);

  const { data: meData } = API.useGetMe({ accessToken: accessToken });

  const { mutateAsync: purchase } = API.usePurchase();
  const { mutateAsync: completePurchase } = API.useCompletePurchase();

  const onClickPurchase = amount => {
    const merchantUid = `mid_${uuid()}`;
    purchase({ amount, merchantUid, accessToken }).then(res => {
      if (res.success) {
        showPurchaseModal(merchantUid, amount).then(rsp => {
          if (rsp.success) {
            completePurchase({
              imp_uid: rsp.imp_uid,
              merchant_uid: merchantUid,
            }).then(res => {
              if (res.success) {
                alert('결제가 완료되었습니다.');
              } else {
                alert('결제가 실패하였습니다.');
              }
            });
          }
        });
      }
    });
  };

  const showPurchaseModal = async (merchantUid, amount) => {
    return new Promise(resolve => {
      IMP.request_pay(
        {
          pg: 'html5_inicis.INIBillTst',
          pay_method: 'card',
          merchant_uid: merchantUid, // 주문번호
          name: '포인트 충전',
          amount, // 숫자 타입
          buyer_email: '',
          buyer_name: '',
          buyer_tel: '',
          buyer_addr: '',
          buyer_postcode: '',
        },
        function (rsp) {
          resolve(rsp);
        },
      );
    });
  };

  return (
    <Modal modalState={modalState}>
      <div className="w-full lg:w-96 p-10 flex flex-col mx-auto bg-white">
        <p className="border-b-2 font-bold flex w-full my-5 pb-3 text-2xl">
          나의 이용정보
        </p>
        <div className="flex text-xl">
          보유 캐쉬 :<p className="font-bold pl-1">{meData?.data?.point}</p>
        </div>
        <p className="border-b-2 font-bold flex w-full my-5 pb-3 text-2xl">
          캐쉬 충전
        </p>
        {pointList.map(point => (
          <div key={point} className="flex text-xl py-2 place-items-center">
            <CurrencyDollarIcon className="h-8 w-8 stroke-yellow2" />
            <p className="w-24 text-right px-2">{point}</p>캐쉬
            <button
              className="w-28 text-center font-semibold border-yellow2 border-2 ml-auto hover:opacity-50 transition-opacity"
              onClick={() => onClickPurchase(point)}
            >
              {point}원
            </button>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default ShopModal;
