import React, { Fragment, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { API } from '../../api/API';
// import useLoginCheck from './../../hooks/UseLoginCheck';
import dateFormatter from 'utils/dateFormatter';
import {
  classReservationValidator,
  freeReservationValidator,
  validateChecker,
} from 'utils/validator';

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const dayNames = ['일', '월', '화', '수', '목', '금', '토'];
const defaultReservation = {
  date: null,
  startAt: null,
  peopleNum: 1,
  course: null,
  refund: false,
};

const timeFormat = time => {
  const hour = parseInt(time / 60);
  const minute = parseInt(time % 60);
  let result = `${hour}시간`;
  if (minute > 0) {
    result += ` ${minute}분`;
  }
  return result;
};

const formatStartAt = (date, start) => {
  const result = new Date(date);
  result.setHours(start.split(':')[0]);
  result.setMinutes(start.split(':')[1]);
  result.setSeconds(0);
  return result;
};

const filteredCorse = (courses, date) => {
  if (!date) return [];
  const day = date.getDay();
  const filtered = courses.filter(course => {
    const courseDay = course.day.split('');
    courseDay.unshift(courseDay.pop());
    return courseDay[day] === '1';
  });
  return filtered;
};

const CourseSelector = ({ reservation, setReservation, gymData }) => {
  if (reservation.course !== '' && reservation.course !== null) {
    return (
      <table className="table-fixed w-full">
        <thead>
          <tr className="border-2 border-gray-300">
            <th className="text-xl text-center w-4/12">이름</th>
            <th className="text-xl text-center w-3/12">인원</th>
            <th className="text-xl text-center w-4/12">시간</th>
            <th className="text-xl text-cente w-1/12" />
          </tr>
        </thead>
        <tbody>
          <tr className="h-2" />
          <tr
            key={reservation.course._id}
            className="border-2 border-gray-300 h-16"
          >
            <td className="text-xl text-center">{reservation.course.name}</td>
            <td className="text-xl text-center">{`${
              reservation.course.offlinePeople + reservation.course.onlinePeople
            } / ${reservation.course.maxPeople}`}</td>
            <td className="text-xl text-center">{`${
              reservation.course.start
            } (${timeFormat(reservation.course.time)})`}</td>
            <td className="text-xl text-center flex h-16 place-content-center place-items-center">
              <button
                onClick={() => {
                  setReservation({
                    ...reservation,
                    course: null,
                  });
                }}
                className="bg-red1 text-white h-10 rounded-xl w-20 hidden lg:block"
              >
                취소
              </button>
              <button
                onClick={() => {
                  setReservation({
                    ...reservation,
                    course: null,
                  });
                }}
                className="bg-red1 text-white text-sm h-6 rounded-xl w-6 lg:hidden"
              >
                X
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  const courses = filteredCorse(gymData?.data.courses, reservation?.date);

  if (courses.length === 0) {
    return (
      <div className="w-full h-20 flex place-content-center place-items-center">
        <p className="text-xl">해당 날짜에 수업이 없습니다.</p>
      </div>
    );
  } else {
    return (
      <table className="table-fixed w-full">
        <thead>
          <tr className="border-2 border-gray-300">
            <th className="text-xl text-center w-4/12">이름</th>
            <th className="text-xl text-center w-3/12">인원</th>
            <th className="text-xl text-center w-4/12">시간</th>
            <th className="text-xl text-cente w-1/12" />
          </tr>
        </thead>
        <tbody>
          <tr className="h-2" />
          {filteredCorse(gymData?.data.courses, reservation?.date).map(
            course => (
              <tr key={course._id} className="border-2 border-gray-300 h-16">
                <td className="text-xl text-center">{course.name}</td>
                <td className="text-xl text-center">{`${
                  course.offlinePeople + course.onlinePeople
                } / ${course.maxPeople}`}</td>
                <td className="text-xl text-center">{`${
                  course.start
                } (${timeFormat(course.time)})`}</td>
                <td className="text-xl text-center flex h-16 place-content-center place-items-center">
                  <button
                    onClick={() => {
                      setReservation({
                        ...reservation,
                        course: course,
                      });
                    }}
                    className="bg-skyblue2 text-white h-10 rounded-xl w-20 hidden lg:block"
                  >
                    선택
                  </button>
                  <button
                    onClick={() => {
                      setReservation({
                        ...reservation,
                        course: course,
                      });
                    }}
                    className="bg-skyblue2 text-white text-sm h-6 rounded-xl w-6 lg:hidden"
                  >
                    ✓
                  </button>
                </td>
              </tr>
            ),
          )}
        </tbody>
      </table>
    );
  }
};

const CustomCalendar = () => {
  const [date, setDate] = useState(new Date());

  const handleDateChange = newDate => {
    setDate(newDate);
  };

  return (
    <div className="calendar-container">
      <h2>예약하기</h2>
      <div className="price-info">20,000원</div>
      <h3>날짜와 시간을 선택해 주세요.</h3>
      <Calendar onChange={handleDateChange} value={date} calendarType="US" />
      <div className="selected-date">
        {date && <p>선택한 날짜: {date.toDateString()}</p>}
      </div>
    </div>
  );
};

const Reservation = () => {
  const { id } = useParams();
  const { accessToken } = useSelector(state => state.userAuth);
  const navigate = useNavigate();
  const [reservation, setReservation] = useState({
    ...defaultReservation,
  });
  const [refundOpen, setRefundOpen] = useState(false);

  // useLoginCheck();

  const { data: gymData } = API.useGetGymDetails({ gymId: id, accessToken });
  const { mutateAsync: createReservation } = API.useCreateReservation();

  const createDate = () => {
    if (!gymData) return [];
    const now = new Date();
    let result = [];
    for (let i = 0; i < 14; i++) {
      const date = new Date(now);
      const openDay = gymData?.data?.openDay?.split('');
      const holiday = gymData?.data?.holidays.map(holiday => holiday.date);
      openDay.unshift(openDay.pop());
      if (
        openDay[date.getDay()] === '0' ||
        holiday.includes(dateFormatter(date))
      ) {
        result.push({ date, disabled: true });
      } else {
        result.push({ date, disabled: false });
      }
      now.setDate(now.getDate() + 1);
    }
    return result;
  };

  const onSubmit = () => {
    if (reservation.peopleNum <= 0) {
      alert('인원은 1명 이상이어야 합니다.');
    } else if (!reservation.refund) {
      alert('환불규정에 동의해주세요.');
    } else {
      let result;
      if (gymData?.data.type === 'class') {
        result = validateChecker(reservation, classReservationValidator);
      } else if (gymData?.data.type === 'free') {
        result = validateChecker(reservation, freeReservationValidator);
      }
      if (result.success) {
        createReservation({
          gymId: id,
          accessToken,
          startAt: formatStartAt(
            reservation.date,
            reservation.course ? reservation.course.start : reservation.startAt,
          ),
          peopleNum: parseInt(reservation.peopleNum),
          courseId: reservation.course?._id || '',
        }).then(res => {
          if (res.success) {
            alert('예약이 완료되었습니다.');
            navigate('/');
          } else {
            alert('예약에 실패하였습니다.');
          }
        });
      } else {
        alert(result.message);
      }
    }
  };

  return (
    <>
      <div className="w-full max-w-[40rem] px-2 lg:px-0 flex flex-col mx-auto">
        {/* <div className="border-b-2 flex w-full my-5 pb-3">
          <h1 className="text-lg lg:text-3xl font-bold">
            {gymData?.data?.name}
          </h1>
          <h1 className="text-sm lg:text-xl font-bold ml-2 lg:ml-5 mt-auto">
            {gymData?.data?.address}
          </h1>
        </div> */}
        <CustomCalendar />
        <div className="w-full overflow-x-scroll gap-x-5 justify-start grid grid-flow-col-dense scrollbar-hide scroll-smooth">
          {createDate().map(date => (
            <button
              onClick={() =>
                setReservation(prev => ({
                  ...prev,
                  date: date.date,
                  course: null,
                  startAt: null,
                }))
              }
              disabled={date.disabled}
              key={date.date}
              className={`w-14 h-14 lg:w-20 lg:h-20 rounded-xl border-2 ${
                date.disabled
                  ? 'text-gray-200 border-gray-200'
                  : dateFormatter(reservation.date) === dateFormatter(date.date)
                    ? 'text-yellow2 border-yellow2'
                    : 'text-skyblue2 border-skyblue2'
              }`}
            >
              <p className="lg:text-xl">{date.date.getDate()}</p>
              <p className="lg:text-xl">{dayNames[date.date.getDay()]}</p>
            </button>
          ))}
        </div>
        {gymData?.data?.type === 'class' && (
          <div className="w-full mt-5">
            <p className="text-2xl font-semibold mb-3">- 수업 선택</p>
            <CourseSelector
              reservation={reservation}
              setReservation={setReservation}
              gymData={gymData}
            />
          </div>
        )}
        {gymData?.data?.type === 'free' && (
          <div className="w-full mt-5">
            <p className="text-2xl font-semibold mb-3">- 시작 시간</p>
            <input
              type="time"
              value={reservation.startAt}
              onChange={e =>
                setReservation(prev => ({
                  ...prev,
                  startAt: e.target.value,
                }))
              }
              className="w-full border-2 text-xl bg-transparent border-gray-300 p-2"
            />
          </div>
        )}
        <div className="w-full mt-5">
          <p className="text-2xl font-semibold mb-3">- 인원</p>
          <input
            type="number"
            value={reservation.peopleNum}
            onChange={e =>
              setReservation(prev => ({
                ...prev,
                peopleNum: e.target.value > 0 ? e.target.value : 0,
              }))
            }
            className="w-full border-2 text-xl bg-transparent border-gray-300 p-2"
          />
        </div>
        <div className="w-full mt-5">
          <p className="text-2xl font-semibold mb-3 mr-5">- 비용</p>
          <div className="flex">
            <p className="text-2xl">총 비용</p>
            <p className="text-2xl mx-5 text-skyblue2 font-bold">
              {reservation.peopleNum *
                (gymData?.data?.dayPoint + gymData?.data?.gymFee)}
            </p>
            <p className="text-2xl">캐쉬</p>
          </div>
        </div>
        <div className="w-full mt-5">
          <p className="text-2xl font-semibold mb-3 mr-5">- 환불 규정</p>
          <div className="flex">
            <button
              onClick={() => setRefundOpen(!refundOpen)}
              className={`text-xl border-2 rounded-xl text-center w-24 mr-5 ${
                !refundOpen
                  ? 'text-skyblue2 border-skyblue2'
                  : 'text-red1 border-red1'
              }`}
            >
              {refundOpen ? '닫기' : '보기'}
            </button>
            <div
              className={`text-xl border-2 rounded-xl text-center w-24 mr-5 ${
                reservation.refund
                  ? 'text-skyblue2 border-skyblue2'
                  : 'text-red1 border-red1'
              }`}
            >
              {reservation.refund ? '동의' : '비동의'}
            </div>
          </div>
          <div
            className={`rounded-xl w-full transition-all flex flex-col p-5 ${
              refundOpen ? 'h-96 my-5 border-2 opacity-100' : 'h-0 opacity-0'
            }`}
          >
            <button
              onClick={() =>
                setReservation(prev => ({ ...prev, refund: !prev.refund }))
              }
              className={`text-xl border-2 rounded-xl text-center w-24 mt-auto ml-auto ${
                reservation.refund
                  ? 'text-skyblue2 border-skyblue2'
                  : 'text-gray-300 border-gray-300'
              }`}
            >
              동의
            </button>
          </div>
        </div>
        <button
          disabled={!reservation.refund}
          onClick={onSubmit}
          className="ml-auto text-xl w-40 h-10 mb-6 bg-yellow1 rounded-xl disabled:bg-gray-300 disabled:text-gray-600"
        >
          예약하기
        </button>
      </div>
    </>
  );
};
export default Reservation;
