import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import AppRoute from './route/AppRoute';
import { Provider } from 'react-redux';
import store from './store/store';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { QueryClient, QueryClientProvider } from 'react-query';
import { IMP } from 'api/API';

IMP.init('imp34514765');

const queryClient = new QueryClient();

const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppRoute />
      </PersistGate>
    </Provider>
  </QueryClientProvider>,
);
