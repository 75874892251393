import React, { useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

const DropdownSelector = ({
  className = '',
  options = [],
  value = options[0],
  onChange = v => console.log(v),
}) => {
  const [open, setOpen] = useState(false);

  const onClick = option => {
    setOpen(false);
    onChange(option);
  };

  return (
    <div className={className}>
      <button
        onClick={() => setOpen(prev => !prev)}
        className="w-[93px] h-11 px-4 py-3 bg-red-50 rounded-xl justify-center items-center gap-4 inline-flex"
      >
        <p className="text-orange-600 text-sm font-medium leading-tight tracking-wide">
          {value?.label}
        </p>
        <ChevronDownIcon
          className={`ml-auto my-auto w-4 h-4 ${
            open ? '-rotate-180' : 'rotate-0'
          } transition-all duration-300`}
          color="#E53700"
        />
      </button>
      <div
        className={`absolute mt-2 rounded-xl bg-red-50 flex flex-col w-[93px] z-20 ${
          open ? 'max-h-52 overflow-y-scroll' : 'max-h-0 overflow-hidden'
        } transition-all duration-300 ease-in-out`}
      >
        {options?.map((option, index) => (
          <button
            key={`${option.id}_${index}`}
            onClick={() => onClick(option)}
            className="w-full px-4 py-3 transition-colors duration-300 hover:bg-white3 text-left flex"
          >
            <p className="text-orange-600 text-sm font-medium leading-tight tracking-wide">
              {option.label}
            </p>
          </button>
        ))}
      </div>
    </div>
  );
};

export default DropdownSelector;
