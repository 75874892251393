import React from 'react';

const BottomNav = () => {
  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-300 pb-5 z-30 ">
      <div className="flex justify-around py-4 max-w-[40rem] mx-auto">
        <div className="text-center">
          <button className="focus:outline-none">
            <img
              className="mx-auto mb-1.5"
              src="/imgs/ic/bn-home.svg"
              alt="home"
            />
            <span className="block text-xs">홈 피드</span>
          </button>
        </div>
        <div className="text-center">
          <button className="focus:outline-none">
            <img
              className="mx-auto mb-1.5"
              src="/imgs/ic/bn-list.svg"
              alt="home"
            />
            <span className="block text-xs">예약내역</span>
          </button>
        </div>
        <div className="text-center">
          <button className="focus:outline-none">
            <img
              className="mx-auto mb-1.5"
              src="/imgs/ic/bn-notice.svg"
              alt="home"
            />
            <span className="block text-xs">공지사항</span>
          </button>
        </div>
        <div className="text-center">
          <button className="focus:outline-none">
            <img
              className="mx-auto mb-1.5"
              src="/imgs/ic/bn-my.svg"
              alt="home"
            />
            <span className="block text-xs">내 정보</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default BottomNav;
