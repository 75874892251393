import Modal from 'component/Modal/Modal';

const CancelModal = ({ cancel, setCancel, defaultCancel, onSubmit }) => {
  return (
    <Modal
      modalState={[cancel.open, v => setCancel(prev => ({ ...prev, open: v }))]}
      onClose={() => {
        setCancel({ ...defaultCancel, open: false });
      }}
    >
      <div className="p-10 flex flex-col bg-white w-screen">
        <div className="w-full flex flex-col col-span-2">
          <p className="text-2xl text-gray-500 font-semibold mb-2">- 사유</p>
          <textarea
            value={cancel.reason}
            onChange={e => {
              setCancel(prev => ({ ...prev, reason: e.target.value }));
            }}
            className="w-full h-[20rem] border-2 text-xl bg-transparent border-gray-300 p-2 mb-2 resize-none"
          />
        </div>
        <div className="w-full flex mt-5">
          <button
            onClick={() => {
              setCancel({ ...defaultCancel });
            }}
            className="flex-1 bg-gray-300 text-2xl text-gray-500 font-semibold py-2 rounded-xl"
          >
            취소
          </button>
          <button
            onClick={onSubmit}
            className="flex-1 bg-skyblue2 text-2xl text-white font-semibold py-2 rounded-xl ml-5"
          >
            확인
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CancelModal;
