import { API } from 'api/API';
import { PlayIcon } from '@heroicons/react/24/outline';
import ImageInput from 'component/ImageInput/ImageInput';
import EditGymLayout from 'layout/Admin/EditGymLayout';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getImageFile } from 'utils/imageHelper';
import { useSelector } from 'react-redux';

const EditGymBanner = () => {
  const { accessToken } = useSelector(state => state.adminAuth);

  const { gymid } = useParams();
  const navigate = useNavigate();
  const [newBanner, setNewBanner] = useState({
    image: '',
  });

  const { data: gymData } = API.useGetGymDetails({ gymId: gymid });
  const { data: gymBannerData, refetch: refetchGymBanner } =
    API.useGetGymBanners({ gymId: gymid, accessToken });
  const { mutateAsync: uploadMedia } = API.useMediaUpload();
  const { mutateAsync: createBanner } = API.useCreateGymBanner();
  const { mutateAsync: changeBannerOrder } = API.useChangeGymBannerOrder();
  const { mutateAsync: deleteBanner } = API.useDeleteGymBanner();

  const onSubmit = () => {
    if (newBanner.image === '') {
      alert('이미지를 선택해주세요.');
      return;
    }
    const file = getImageFile(newBanner.image, 'temp.jpg');
    uploadMedia({ file: file, mediaId: `${gymid}/banner`, accessToken }).then(
      res => {
        if (res.success) {
          createBanner({
            gymId: gymid,
            image: res.data,
            accessToken,
          }).then(res => {
            if (res.success) {
              alert('배너가 추가되었습니다.');
              refetchGymBanner();
            } else {
              alert('배너 추가에 실패했습니다.');
            }
          });
        } else {
          alert('이미지 업로드에 실패했습니다.');
        }
      },
    );
  };

  const onDelete = id => {
    deleteBanner({ bannerId: id, accessToken }).then(res => {
      if (res.success) {
        alert('배너가 삭제되었습니다.');
        refetchGymBanner();
      } else {
        alert('배너 삭제에 실패했습니다.');
      }
    });
  };

  const onChangeOrder = (id, direction) => {
    changeBannerOrder({ bannerId: id, direction, accessToken }).then(() => {
      refetchGymBanner();
    });
  };

  useEffect(() => {
    if (gymData) {
      if (!gymData?.success) {
        alert('잘못된 접근입니다.');
        navigate(-1);
      }
    }
  }, [gymData, navigate]);

  if (!gymData?.success) return null;
  return (
    <EditGymLayout gym={gymData?.data}>
      <div className="p-10 flex flex-col">
        <div className="h-20 w-full flex flex-row">
          <p className="text-3xl font-semibold text-gray-500">
            * 체육관 배너 수정
          </p>
        </div>
        <div className="flex flex-col mt-10">
          <div className="flex mb-2">
            <p className="text-2xl text-gray-500 font-semibold">
              - 새로운 배너
            </p>
          </div>
          <table className="table-fixed border-collapse border border-gray-300">
            <thead>
              <tr>
                <th className="w-1/12 border border-gray-300"></th>
                <th className="w-10/12 border border-gray-300">이미지</th>
                <th className="w-1/12 border border-gray-300"></th>
              </tr>
            </thead>
            <tbody>
              <tr className="group">
                <td className="border border-gray-300"></td>
                <td className="border border-gray-300">
                  <div className="flex w-full h-full justify-around">
                    <ImageInput
                      className="w-[60rem] h-[45rem] bg-white"
                      image={newBanner.image}
                      setImage={data =>
                        setNewBanner(prev => ({
                          ...prev,
                          image: data,
                        }))
                      }
                    />
                  </div>
                </td>
                <td className="border border-gray-300">
                  <div className="flex w-full h-full justify-around">
                    <button
                      onClick={onSubmit}
                      className="bg-skyblue2 text-white font-bold py-2 px-4 rounded"
                    >
                      추가
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex flex-col mt-10">
          <div className="flex mb-2">
            <p className="text-2xl text-gray-500 font-semibold">- 배너 목록</p>
          </div>
          <table className="table-fixed border-collapse border border-gray-300">
            <thead>
              <tr>
                <th className="w-1/12 border border-gray-300"></th>
                <th className="w-10/12 border border-gray-300">이미지</th>
                <th className="w-1/12 border border-gray-300"></th>
              </tr>
            </thead>
            <tbody>
              {gymBannerData?.data?.map(item => (
                <tr key={item._id} className="group">
                  <td className="border border-gray-300">
                    <div className="flex flex-col h-full justify-around">
                      <button
                        onClick={() => onChangeOrder(item._id, 'up')}
                        className="w-6 mx-auto group-first:invisible"
                      >
                        <PlayIcon className="w-6 h-6 stroke-2 -rotate-90"></PlayIcon>
                      </button>
                      <button
                        onClick={() => onChangeOrder(item._id, 'down')}
                        className="w-6 mx-auto group-last:invisible"
                      >
                        <PlayIcon className="w-6 h-6 stroke-2 rotate-90" />
                      </button>
                    </div>
                  </td>
                  <td className="border border-gray-300">
                    <div className="flex w-full h-full justify-around">
                      <img
                        src={item.image}
                        alt="banner"
                        className="w-[60rem] h-[45rem] bg-gray-400"
                      />
                    </div>
                  </td>
                  <td className="border border-gray-300">
                    <div className="flex w-full h-full justify-around">
                      <button
                        onClick={() => onDelete(item._id)}
                        className="bg-red1 text-white font-bold py-2 px-4 rounded"
                      >
                        삭제
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </EditGymLayout>
  );
};

export default EditGymBanner;
