import { ajax } from './API';
import { useMutation } from 'react-query';

const usePurchase = () => {
  return useMutation(({ accessToken, amount, merchantUid }) =>
    ajax({
      method: 'POST',
      url: '/purchases',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        amount,
        merchantUid,
      },
    }),
  );
};

const useCompletePurchase = () => {
  return useMutation(({ accessToken, impUid, merchantUid }) =>
    ajax({
      method: 'POST',
      url: '/purchases/complete',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        imp_uid: impUid,
        merchant_uid: merchantUid,
      },
    }),
  );
};

const PurchaseAPI = {
  usePurchase,
  useCompletePurchase,
};

export default PurchaseAPI;
