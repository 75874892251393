import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { API } from '../../api/API';
import EditGymLayout from 'layout/Admin/EditGymLayout';
import { useState } from 'react';
import CancelModal from '../MyPage/CancelModal';

const reservationStatus = {
  wait: {
    name: '대기',
    btn: {
      disabled: false,
      label: '취소 신청',
      style: 'border-red1 text-red1',
    },
  },
  cancel: {
    name: '취소',
    btn: {
      disabled: true,
      label: '취소 완료',
      style: 'border-skyblue2 text-skyblue2',
    },
  },
  end: {
    name: '만료',
    btn: {
      disabled: false,
      label: '취소 신청',
      style: 'border-red1 text-red1',
    },
  },
  cancel_wait: {
    name: '취소 대기',
    btn: {
      disabled: true,
      label: '취소 대기',
      style: 'border-yellow2 text-yellow2',
    },
  },
};

const defaultCancel = {
  open: false,
  reservationId: null,
  reason: '',
};

const EditGymReservation = () => {
  const { accessToken } = useSelector(state => state.adminAuth);
  const { gymid } = useParams();

  const { data: gymData } = API.useGetGymDetails({
    gymId: gymid,
  });

  const { data: reservations } = API.useGetGymReservations({
    accessToken: accessToken,
    gymId: gymid,
    master: false,
  });

  const [cancel, setCancel] = useState({ ...defaultCancel });

  const { mutateAsync: cancelReservation } = API.useCancelReservation();

  const onCancelReservation = () => {
    if (cancel.reason === '') {
      alert('취소 사유를 입력해주세요.');
    } else if (cancel.reservationId === null) {
      alert('예약 정보가 없습니다.');
    } else {
      cancelReservation({
        accessToken: accessToken,
        reservationId: cancel.reservationId,
        reason: cancel.reason,
      }).then(res => {
        if (res.success) {
          alert('취소 신청이 완료되었습니다.');
        } else {
          alert('취소 신청에 실패하였습니다.');
        }
        setCancel({ ...defaultCancel });
      });
    }
  };

  if (!gymData?.success || !reservations?.success) return null;
  return (
    <EditGymLayout gym={gymData.data}>
      <div className="w-full max-w-[60rem] flex flex-col px-2 lg:px-0 mx-auto">
        {/*<DropdownSelector options={[]} value={null} onChange={() => {}} />*/}
        <p className="border-b-2 flex w-full my-5 pb-3 text-2xl">예약 현황</p>
        <div className="flex w-full max-h-60 overflow-y-scroll scrollbar-show">
          <table className="table-fixed w-full">
            <thead>
              <tr className="border-2 border-gray-300">
                <th className="text-xl text-center w-2/6">체육관 이름</th>
                <th className="text-xl text-center w-1/6">비용</th>
                <th className="text-xl text-center w-2/6">상태</th>
                <th className="text-xl text-center w-1/6"></th>
              </tr>
            </thead>
            <tbody>
              <tr className="h-2" />
              {reservations?.data?.map(reservation => (
                <tr
                  key={reservation._id}
                  className="border-2 border-gray-300 h-16"
                >
                  <td className="lg:text-xl text-center">
                    {reservation.gymName}
                  </td>
                  <td className="lg:text-xl text-center">
                    {reservation.point}
                  </td>
                  <td className="lg:text-xl text-center">
                    {reservationStatus[reservation.status].name}
                  </td>
                  <td className="lg:text-xl text-center flex h-16 place-content-center place-items-center">
                    <button
                      disabled={
                        reservationStatus[reservation.status].btn.disabled
                      }
                      onClick={() => {
                        setCancel({
                          open: true,
                          reservationId: reservation._id,
                          reason: '',
                        });
                      }}
                      className={`h-10 text-sm lg:text-md rounded-xl w-16 lg:w-24 border-2 ${
                        reservationStatus[reservation.status].btn.style
                      }`}
                    >
                      {reservationStatus[reservation.status].btn.label}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <CancelModal
        cancel={cancel}
        setCancel={setCancel}
        defaultCancel={defaultCancel}
        onSubmit={onCancelReservation}
      />
    </EditGymLayout>
  );
};

export default EditGymReservation;
