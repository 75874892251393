import { API } from 'api/API';
import NeedLoginLayout from 'layout/Admin/NeedLoginLayout';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const AddOfficialHoliday = () => {
  const { accessToken } = useSelector(state => state.adminAuth);
  const navigate = useNavigate();

  const [newHoliday, setNewHoliday] = useState({
    startDate: '',
    endDate: '',
    reason: '',
  });

  const { mutateAsync: createHoliday } = API.useSetOfficialHoliday();

  const onSubmit = () => {
    if (!newHoliday.startDate) {
      alert('시작일을 입력해주세요.');
    } else if (!newHoliday.endDate) {
      alert('종료일을 입력해주세요.');
    } else if (!newHoliday.reason) {
      alert('사유를 입력해주세요.');
    } else {
      let holidays = [];
      for (
        let i = new Date(newHoliday.startDate);
        i <= new Date(newHoliday.endDate);
        i.setDate(i.getDate() + 1)
      ) {
        holidays.push({
          date: i.toISOString().slice(0, 10),
          reason: newHoliday.reason,
        });
      }
      createHoliday({
        accessToken,
        holidays,
      }).then(res => {
        if (res.success) {
          alert('공휴일이 추가되었습니다.');
          navigate(-1);
        } else {
          alert('공휴일 추가에 실패하였습니다.');
        }
      });
    }
  };

  return (
    <NeedLoginLayout>
      <div className="p-10 flex flex-col">
        <div className="h-20 w-full flex flex-row">
          <p className="text-3xl font-semibold text-gray-500">* 공휴일 추가</p>
        </div>
        <div className="flex flex-col">
          <div className="flex">
            <input
              type="date"
              value={newHoliday.startDate}
              onChange={e =>
                setNewHoliday(prev => ({
                  ...prev,
                  startDate: e.target.value,
                }))
              }
              className="border-2 text-xl bg-transparent border-gray-300 p-2"
            />
            <p className="text-xl mx-3 my-auto">~</p>
            <input
              type="date"
              value={newHoliday.endDate}
              onChange={e =>
                setNewHoliday(prev => ({ ...prev, endDate: e.target.value }))
              }
              className="border-2 text-xl bg-transparent border-gray-300 p-2"
            />
            <input
              value={newHoliday.reason}
              onChange={e =>
                setNewHoliday(prev => ({ ...prev, reason: e.target.value }))
              }
              className="border-2 text-xl bg-transparent border-gray-300 p-2 ml-5"
            />
            <button
              className="bg-skyblue2 text-white text-xl rounded-xl ml-5 px-5"
              onClick={onSubmit}
            >
              추가
            </button>
          </div>
        </div>
      </div>
    </NeedLoginLayout>
  );
};

export default AddOfficialHoliday;
