import React from 'react';
import { Link } from 'react-router-dom';
import { FaceSmileIcon } from '@heroicons/react/24/outline';

const SideBar = ({ items, title, mainPath }) => {
  return (
    <div className="h-screen w-sidebar flex-col bg-skyblue1 px-3 hidden lg:flex">
      <div className="w-full h-header border-b-2 border-skyblue2 flex flex-row">
        <FaceSmileIcon className="h-12 w-12 stroke-white1 my-auto" />
        <Link to={mainPath}>
          <p className="h-full flex items-center ml-3 text-xl text-white1 font-semibold">
            {title}
          </p>
        </Link>
      </div>
      <div className="px-5">
        {items.map(item => (
          <Link
            key={item.id}
            to={item.link}
            className="w-full h-12 flex flex-row py-4"
          >
            <item.Icon className="w-6 h-6 my-auto stroke-white1 stroke-2" />
            <p className="ml-3 my-auto text-white1 text-lg">{item.title}</p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default SideBar;
