import { useQuery } from 'react-query';
import { ajax } from './API';
const useGetPointSum = ({ accessToken }) => {
  const options = {
    method: 'GET',
    url: '/statistic/point-sum',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return useQuery('users', () => ajax(options));
};

const StatisticAPI = { useGetPointSum };

export default StatisticAPI;
