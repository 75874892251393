import BaseLayout from 'layout/Admin/BaseLayout';
import React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

const LoginCheck = ({ children }) => {
  const adminAuth = useSelector(state => state.adminAuth);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!adminAuth.isLogin) {
      navigate('/admin/login', {
        replace: true,
        state: { nextLink: location.pathname },
      });
    }
  });

  if (adminAuth.isLogin) {
    return children;
  } else {
    return <BaseLayout />;
  }
};

export default LoginCheck;
